import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {FeedbackFormService} from "../services/feedback/feedbackform.service";
import {Title} from "@angular/platform-browser";

interface MailReturn {
  status: string;
}

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})

export class FeedbackComponent {
  feedbackForm = this.fb.group({
    m1: ['', Validators.required],
    m1Message: [''],
    m2: ['', Validators.required],
    m2Message: [''],
    m3: ['', Validators.required],
    m3Message: [''],
    m4: ['', Validators.required],
    m4Message: [''],
    m5: ['', Validators.required],
    m5Message: [''],
    m6: ['', Validators.required],
    m6Message: [''],
    m7: ['', Validators.required],
    m7Message: [''],
    m8: ['', Validators.required],
    m8Message: [''],
    message: ['']
  });
  status = null;


  constructor(
    private fb: FormBuilder,
    private FeedbackformService: FeedbackFormService,
    private titleService: Title
  ) {
  }

  sendFeedback() {
    this.FeedbackformService.sendMail(this.feedbackForm.value).subscribe((data: MailReturn) => {
      this.status = data.status;
    })
  }

  ngOnInit() {
    this.setTitle('Feedback - Munich Urban Climate Trail');
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }


}
