import {Component} from "@angular/core";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html'
})

export class ImprintComponent {
  constructor(private titleService: Title) {
  }

  ngOnInit() {
    this.setTitle('Impressum - Munich Urban Climate Trail');
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
