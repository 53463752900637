<ng-container *ngIf="slidesItems !== undefined && slidesItems !== null && slidesItems.length > 0">
  <div class="gallery__popup__wrapper">
    <div (click)="onGalleryCloseClick()"
         class="gallery__close">
      <i class="icon icon--small close"></i>
    </div>
    <swiper (indexChange)="onIndexChange($event)"
            [(index)]="imageActiveIndex"
            [config]="configSwiper"
            [disabled]="slidesItems.length === 1">
      <ng-container *ngFor="let mediaItem of slidesItems">
        <div [ngClass]="{'mode--portrait': isPortrait, 'mode--landscape': !isPortrait}" class="media__item">
          <div style="display: inline-block;margin: 0 auto; position: relative">
            <img
              src="https://cdn.muenchen-p.de/image/fetch/c_lfill,w_1200/{{mediaItem.url}}" style="margin-top: 20px;max-height: 700px;"/>
            <span *ngIf="mediaItem.copyright != null" class="copyright">{{mediaItem.copyright}}</span>
          </div>
          <div class="caption">
            {{mediaItem.caption}}
          </div>
        </div>
      </ng-container>
    </swiper>
  </div>
</ng-container>
