import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ContentService} from "../services/content/content.service";
import {SystemService} from "../services/system/system.service";
import {SharingService} from "../services/system/sharing.service";
import {Title} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-startpage',
  templateUrl: './startpage.component.html',
  styleUrls: ['./startpage.component.scss']
})
export class StartpageComponent implements OnInit {

  startpageArticle;
  deferredPrompt;
  isMobileDevice: boolean = false;
  isViewMode: number = 0;

  constructor(private contentService: ContentService,
              private sharingService: SharingService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private titleService: Title) {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    if (isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }
  }

  checkDeviceType() {
    this.isMobileDevice = SystemService.checkDeviceType(this.platformId);

    let isDesktop = window.matchMedia("(min-width: 1000px)").matches,
      isHybrid = window.matchMedia("(max-width: 1000px)").matches,
      isMobile = window.matchMedia("(max-width: 800px)").matches;

    if (isDesktop) {
      this.isViewMode = 0;
    }

    if (isHybrid) {
      this.isViewMode = 1;
    }

    if (isMobile) {
      this.isViewMode = 2;
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }

    this.contentService.getLehrpfadDetailSingle(2).subscribe((result) => {
      if (result !== undefined && result !== null) {
        this.startpageArticle = result;
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('beforeinstallprompt', (e) => {

        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        // Update UI notify the user they can add to home screen

        //this.btnAdd.style.display = 'block';
      });
      this.setTitle('Startseite - Munich Urban Climate Trail');
    }
  }

  onJumpToMap() {
    let sharingData = {
      hideLevelOne: true
    };
    this.sharingService.emitChange(sharingData);
  };

  downloadClicked() {
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
        } else {
        }
        this.deferredPrompt = null;
      });
  }

  onAnkerClick() {
    let anker = document.getElementById('start');
    if (anker !== undefined && anker !== null) {
      let scrollContainer = <HTMLDivElement>document.getElementsByClassName('container-main')[0];
      scrollContainer.scrollTo({
        top: anker.offsetTop - 20,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

}
