import {Injectable} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private metatagService: Meta,
              private pageTitleService: Title) {

  }

  public createMetatags(metatagTexts = null) {
    console.log('metatagTexts', metatagTexts);

    // change title
    this.pageTitleService.setTitle(metatagTexts.title);


    // remove all tags
    this.removeMetatags();

    this.metatagService.addTags([

      {name: 'title', content: metatagTexts.title},
      {name: 'description', content: metatagTexts.description}

      //{name: 'author', content: 'Landeshauptstadt München'},

      //{name: 'keywords', content: metatagTexts.keywords},

      //{property: 'og:title', content: metatagTexts.title}

    ]);
  }

  removeMetatags() {
    const desc = this.metatagService.getTag('name="description"');
    const author = this.metatagService.getTag('name="author"');
    const keywords = this.metatagService.getTag('name="keywords"');
    const ogTitle = this.metatagService.getTag('property="og:title"');
    const ogUrl = this.metatagService.getTag('property="og:url"');
    const ogSiteName = this.metatagService.getTag('property="og:site_name"');
    const ogDesc = this.metatagService.getTag('property="og:description"');
    const ogImage = this.metatagService.getTag('property="og:image"');

    this.metatagService.removeTagElement(desc);
    this.metatagService.removeTagElement(author);
    this.metatagService.removeTagElement(keywords);
    this.metatagService.removeTagElement(ogTitle);
    this.metatagService.removeTagElement(ogUrl);
    this.metatagService.removeTagElement(ogSiteName);
    this.metatagService.removeTagElement(ogDesc);
    this.metatagService.removeTagElement(ogImage);
  }

  public updateMetatags(metatagTexts = null) {
    if (metatagTexts !== null) {
      // change title
      this.pageTitleService.setTitle(metatagTexts.title);
      // description
      this.metatagService.updateTag({
        name: "description",
        content: metatagTexts.description
      });
      // facebook
      this.metatagService.updateTag({
        property: "og:title",
        content: metatagTexts.title
      });
      this.metatagService.updateTag({
        property: "og:site_name",
        content: metatagTexts.ogSitename
      });
      this.metatagService.updateTag({
        property: "og:url",
        content: metatagTexts.ogUrl
      });
      this.metatagService.updateTag({
        property: "og:description",
        content: metatagTexts.description
      });
      this.metatagService.updateTag({
        property: "og:image",
        content: metatagTexts.image
      });
      // twitter
      this.metatagService.updateTag({
        name: "twitter:title",
        content: metatagTexts.title
      });
      this.metatagService.updateTag({
        name: "twitter:description",
        content: metatagTexts.description
      });

      if (metatagTexts.hasOwnProperty('keywords')) {
        this.metatagService.updateTag({
          name: "keywords",
          content: metatagTexts.keywords
        });
      }
    }
  }
}
