import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-gallery-popup',
  templateUrl: './gallery-popup.component.html',
  styleUrls: ['./gallery-popup.component.scss']
})
export class GalleryPopupComponent implements OnInit {

  @Input() slidesItems: Array<any> = [];
  @Output() closeGallery = new EventEmitter();

  imageActiveIndex: number = 0;

  configSwiper;

  isPortrait: boolean = false;

  constructor() {
    this.checkOrientationMode();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    this.checkOrientationMode();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkOrientationMode();
  }

  ngOnInit() {
    this.checkOrientationMode();
    this.initSwiper();
  }

  ngOnChanges() {
    this.checkOrientationMode();
    this.initSwiper();
  }

  checkOrientationMode() {
    this.isPortrait = window.orientation == 0;
  }

  initSwiper(): void {
    this.configSwiper = {
      observer: false,                // Set to to true to enable automatic update calls.
      direction: 'horizontal',               // Direction of the Swiper (Default: 'horizontal').
      threshold: 0,               // Distance needed for the swipe action (Default: 0).
      spaceBetween: 10,            // Space in pixels between the Swiper items (Default: 0).
      slidesPerView: 1,           // Number of the items per view or 'auto' (Default: 1).
      centeredSlides: false,          // Align active item on center not left (Default: false).
      initialSlide: 0,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false
    }
  }

  onIndexChange(index: number): void {
    this.imageActiveIndex = index;
  }

  onGalleryCloseClick() {
    this.closeGallery.emit();
  }

}
