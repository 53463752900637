<ng-container [ngSwitch]="isViewMode">
  <ng-container *ngSwitchCase="0">
    <app-header [viewmode]="isViewMode"></app-header>

    <app-mainnav (menuLinkClick)="onMenuLinkClicked()" [viewmode]="isViewMode"></app-mainnav>

    <div [ngClass]="{'map--big': mapActive}" class="app-level base">
      <app-map-box-pageview></app-map-box-pageview>
    </div>

    <div *ngIf="quizPopupActive" class="app-level three desktop">
      <div class="container-main">
        <app-quiz-popup *ngIf="quizPopupActive" [quizData]="quizData"></app-quiz-popup>
      </div>
    </div>
    <div *ngIf="articlePopupActive" class="app-level two desktop">
      <div class="container-main">
        <app-article-popup [articleId]="activeArticleId"></app-article-popup>
      </div>
    </div>

    <div *ngIf="!mapActive" class="app-level one desktop">
      <div class="container-main">
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="1">
    <app-header (mainMenuOpened)="onMainMenuOpened($event)" [mainMenuState]="mainnavActive" [viewmode]="isViewMode"></app-header>

    <app-mainnav (menuLinkClick)="onMenuLinkClicked()" *ngIf="mainnavActive" [viewmode]="isViewMode"></app-mainnav>

    <div class="app-level base">
      <app-map-box-pageview></app-map-box-pageview>
    </div>

    <div *ngIf="quizPopupActive" class="app-level three hybrid">
      <div class="container-main">
        <app-quiz-popup *ngIf="quizPopupActive" [quizData]="quizData"></app-quiz-popup>
      </div>
    </div>
    <div *ngIf="articlePopupActive" class="app-level two hybrid">
      <div class="container-main">
        <app-article-popup [articleId]="activeArticleId"></app-article-popup>
      </div>
    </div>

    <div *ngIf="!mapActive" class="app-level one hybrid">
      <div class="container-main">
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="2">
    <app-header (mainMenuOpened)="onMainMenuOpened($event)" [mainMenuState]="mainnavActive" [viewmode]="isViewMode"></app-header>

    <app-mainnav (menuLinkClick)="onMenuLinkClicked()" *ngIf="mainnavActive" [viewmode]="isViewMode"></app-mainnav>

    <div *ngIf="quizPopupActive" class="app-level three mobile">
      <app-quiz-popup *ngIf="quizPopupActive" [quizData]="quizData"></app-quiz-popup>
    </div>
    <div *ngIf="articlePopupActive" class="app-level two mobile">
      <app-article-popup [articleId]="activeArticleId"></app-article-popup>
    </div>

    <div *ngIf="!mapActive" class="app-level one mobile">
      <div class="container-main">
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-container>
</ng-container>
