<section class="section section--intro intro-2 type-teaser">
  <div class="intro__head">
    <div class="breadcrumb">

    </div>
    <h1>Der Munich Urban Climate Trail im Überblick</h1>
  </div>
  <div class="intro__content"></div>
  <div class="intro__footer"></div>
  <div class="copyright">

  </div>
</section>

<section class="section section--white">
  <div class="section__headline blue">Streckenprofile</div>
  <div class="section__content">

    <div *ngFor="let track of tracks" class="accordion">
      <div (click)="onAccordionHeaderClick($event)" class="accordion--header">
        {{ track.name }}
        <i class="icon icon-arrow-right"></i>
      </div>
      <div class="accordion--content">
        <ul>
          <li><strong>Wegstrecke:</strong> {{ track.distance }}</li>
          <li><strong>Dauer:</strong> {{ track.duration }}</li>
          <li><strong>Schwierigkeit:</strong> {{ track.difficulty }}</li>
          <li><strong>Jahreszeit:</strong> {{ track.season }}</li>
          <li><strong>Mobilität:</strong> {{ track.mobility }}</li>
          <li><strong>Besonderheit:</strong> {{ track.special}}</li>
        </ul>
      </div>
    </div>

  </div>
  <div class="wave">
    <svg preserveAspectRatio="none" style="height: 100%; width: 100%;" viewBox="0 0 500 150">
      <path class="wave-path" d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>
    </svg>
  </div>
</section>

<section class="section section--blue">
  <div class="icon icon--section icon--medium knowledge">
  </div>
  <div class="section__content">

    <div *ngFor="let station of stations" class="milestone">
      <div class="milestone--header">
        <img src="https://cdn.muenchen-p.de/image/fetch/c_lfill,w_50,h_50/{{station.medias[0].url}}"/>
        <h3 [innerHTML]="station.name"></h3>
      </div>
      <div class="milestone--content">
        <p [innerHTML]="station.subheadline">
        </p>
        <a (click)="onStationOpenClick($event)"
           [attr.data-stationid]="station.id"
           [attr.data-stationname]="station.name">
          <i class="icon icon--small info" style="float: left"></i>
          Station öffnen
        </a>
      </div>
    </div>
  </div>

</section>

<!--
<section class="section section--white" id="start">
  <div class="section__headline blue">&bdquo;Alles fließt&ldquo;</div>
  <div class="section__content">
    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
      but also the leap into electronic typesetting, remaining essentially unchanged.
    </p>

    <p class="bold">
      Ich hätte dir so viel zu sagen!
    </p>

    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
      but also the leap into electronic typesetting, remaining essentially unchanged.
    </p>

    <p class="bold">
      Die Steckbriefe
    </p>

    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
      but also the leap into electronic typesetting, remaining essentially unchanged.
    </p>
  </div>

  <div class="wave">
    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
      <path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z" class="wave-path"></path>
    </svg>
  </div>
</section>

<section class="section section--blue">
  <div class="section__headline">Kurzbeschreibung</div>
  <div class="section__content">
    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
      but also the leap into electronic typesetting, remaining essentially unchanged.
    </p>
    <a href="mapview.html">Der Lehrpfad im Überblick</a>
  </div>
  <div class="wave">
    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
      <path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z" class="wave-path"></path>
    </svg>
  </div>
</section>

<section class="section section--red">
  <div class="section__headline">Stationen des Naturlehrpfades Würm - Leben im Fluss</div>
  <div class="section__content">
    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
      but also the leap into electronic typesetting, remaining essentially unchanged.
    </p>
  </div>
  <div class="wave">
    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
      <path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z" class="wave-path"></path>
    </svg>
  </div>
</section>

<section class="section section--white">
  <div class="section__content">
    <div class="linklist">
      <span class="linklist__headline">Inhalt</span>
      <div class="linklist__content">
        <div class="linklist__item"><a href="#">Tiere und Artenreichtum der Würm</a></div>
        <div class="linklist__item"><a href="#">Wissensmodul: Jäger und Gejagte</a></div>
        <div class="linklist__item"><a href="#">Quiz: test dein Wissen</a></div>
      </div>
    </div>
  </div>
  <div class="wave">
    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
      <path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z" class="wave-path"></path>
    </svg>
  </div>
</section>

<section class="section section--yellow">
  <div class="section__headline">Stationen des Naturlehrpfades Würm - Leben im Fluss</div>
  <div class="section__content">
    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
      but also the leap into electronic typesetting, remaining essentially unchanged.
    </p>

    <div class="button-row">
      <a href="#" class="button button--blue button--fullsize"><span>Quiz starten</span></a>
    </div>
  </div>
  <div class="wave">
    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
      <path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z" class="wave-path"></path>
    </svg>
  </div>
</section>

<section class="section section--blue">
  <div class="section__content">
    <div class="infobox infobox--blue">
      <div class="infobox__headline">Wusstest Du ...?</div>
      <div class="infobox__content">
        ...<b>dass es</b> lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
      </div>
    </div>
  </div>
  <div class="wave">
    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
      <path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z" class="wave-path"></path>
    </svg>
  </div>
</section>
-->
