<section class="section section--intro type-teaser" style="background-color: #223E6D !important;height:120px">
  <div class="intro__head">
    <h1>Feedback</h1>
  </div>
  <div class="intro__content"></div>
  <div class="intro__footer"></div>
</section>


<section class="section section--white">
  <div class="section__content">

    <form (submit)="sendFeedback()" *ngIf="status == null" [formGroup]="feedbackForm">
        <span>
          <h3>Sind bei Dir technische Probleme bei der Nutzung des Lehrpfades aufgetreten?*</h3>
          <div class="form-wrapper">
            <label class="label--radio" for="m1"><input class="radio" formControlName="m1" id="m1" type="radio" value="1">Ja</label>
            <label class="label--radio" for="ma1"><input class="radio" formControlName="m1" id="ma1" type="radio" value="0">Nein</label>
          </div>
          <textarea formControlName="m1Message"></textarea>
        </span>
      <br/><br/>
      <span>
          <h3>Kommst Du mit der Bedienung des Lehrpfads gut zurecht?*</h3>
          <div class="form-wrapper">
            <label class="label--radio" for="m2"><input class="radio" formControlName="m2" id="m2" type="radio" value="1">Ja</label>
            <label class="label--radio" for="ma2"><input class="radio" formControlName="m2" id="ma2" type="radio" value="0"> Nein</label>
          </div>
          <textarea formControlName="m2Message"></textarea>
        </span>
      <br/><br/>
      <span>
          <h3>Gefällt Dir das Design des Lehrpfads?*</h3>
          <div class="form-wrapper">
            <label class="label--radio" for="m3"><input class="radio" formControlName="m3" id="m3" type="radio" value="1"> Ja</label>
            <label class="label--radio" for="ma3"><input class="radio" formControlName="m3" id="ma3" type="radio" value="0"> Nein</label>
          </div>
          <textarea formControlName="m3Message"></textarea>
        </span>
      <br/><br/>
      <span>
          <h3>Ist die Länge der Wege für Dich gut gewählt?*</h3>
          <div class="form-wrapper">
            <label class="label--radio" for="m4"><input class="radio" formControlName="m4" id="m4" type="radio" value="1"> Ja</label>
            <label class="label--radio" for="ma4"><input class="radio" formControlName="m4" id="ma4" type="radio" value="0"> Nein</label>
          </div>
          <textarea formControlName="m4Message"></textarea>
        </span>
      <br/><br/>
      <span>
          <h3>Sind die Texte verständlich geschrieben?*</h3>
          <div class="form-wrapper">
            <label class="label--radio" for="m5"><input class="radio" formControlName="m5" id="m5" type="radio" value="1"> Ja</label>
            <label class="label--radio" for="ma5"><input class="radio" formControlName="m5" id="ma5" type="radio" value="0"> Nein</label>
          </div>
          <textarea formControlName="m5Message"></textarea>
        </span>
      <br/><br/>
      <span>
          <h3>Findest Du die Themenauswahl interessant?*</h3>
          <div class="form-wrapper">
            <label class="label--radio" for="m6"><input class="radio" formControlName="m6" id="m6" type="radio" value="1"> Ja</label>
            <label class="label--radio" for="ma6"><input class="radio" formControlName="m6" id="ma6" type="radio" value="0"> Nein</label>
          </div>
          <textarea formControlName="m6Message"></textarea>
        </span>
      <br/><br/>
      <span>
          <h3>Hast Du Interesse an weiteren Lehrpfaden oder Umweltwanderwegen in München?*</h3>
          <div class="form-wrapper">
            <label class="label--radio" for="m7"><input class="radio" formControlName="m7" id="m7" type="radio" value="1"> Ja</label>
            <label class="label--radio" for="ma7"><input class="radio" formControlName="m7" id="ma7" type="radio" value="0"> Nein</label>
          </div>
          <textarea formControlName="m7Message"></textarea>
        </span>
      <br/><br/>
      <span>
          <h3>Würdest Du den Lehrpfad gerne als App, z.B. im Playstore oder App Store, herunterladen können?*</h3>
          <div class="form-wrapper">
            <label class="label--radio" for="m8"><input class="radio" formControlName="m8" id="m8" type="radio" value="1"> Ja</label>
            <label class="label--radio" for="ma8"><input class="radio" formControlName="m8" id="ma8" type="radio" value="0"> Nein</label>
          </div>
          <textarea formControlName="m8Message"></textarea>
        </span>
      <br/><br/>
      <span>
          <h3>Wie gefällt Dir der Lehrpfad? Willst Du sonst noch etwas loswerden?</h3>
          <textarea formControlName="message"></textarea>
        </span>
      <button [disabled]="!feedbackForm.valid" class="orange" type="submit">Absenden</button>
    </form>
    <div *ngIf="status == 'success'">
      <h2 style="text-align: initial">Vielen Dank für Dein Feedback!</h2>
    </div>
    <div *ngIf="status == 'error'">
      <h2 style="text-align: initial">Vielen Dank für Dein Feedback!</h2>
    </div>
  </div>
  <div class="wave">
    <svg preserveAspectRatio="none" style="height: 100%; width: 100%;" viewBox="0 0 500 150">
      <path class="wave-path" d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>
    </svg>
  </div>
</section>
