<div class="map" id="map"></div>

<div *ngIf="showNotification == true" [ngClass]="{'big': !isMobileDevice}" class="notification">
  <div class="notification-content">
    <div (click)="closeNotification()" class="btn btn-close icon icon--small close" style="float: right"></div>
    Bitte aktiviere die Standorterfassung auf der Karte, um alle Funktionen des Lehrpfads nutzen zu können!
  </div>
</div>


<div *ngIf="mvStation != null" class="mvg-entry-list">
  <div class="departure-head head">
    <div (click)="onMvgEntryClose()" class="btn icon icon--small close"
         style="float: right"></div>
    <h3>{{mvStation.name}}</h3>
  </div>
  <ol class="mvg-departure-list entry-list">
    <li *ngFor="let departure of mvStation">
      <div class="transport">
        <img *ngIf="departure.product == 'u'" alt="U-Bahn" class="transport-icon"
             src="/assets/images/u-bahn.png">
        <img *ngIf="departure.product == 's'" alt="S-Bahn" class="transport-icon"
             src="/assets/images/s-bahn.png">
        <img *ngIf="departure.product == 't'" alt="Tram" class="transport-icon"
             src="/assets/images/tram.png">
        <img *ngIf="departure.product == 'b'" alt="Bus" class="transport-icon"
             src="/assets/images/bus.png">
        <span [style.background]="departure.lineBackgroundColor" class="colored-number">
                {{ departure.label }}
            </span>
      </div>
      <div class="destination">
        {{ departure.destination }}
      </div>
      <div class="time">
        {{ departure.time }}
      </div>
    </li>
  </ol>
</div>
