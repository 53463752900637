<section class="section section--intro type-teaser" style="background-color: #223E6D !important;height:200px">
  <div class="intro__head" style="top:">
    <h1>Impressum</h1>
  </div>
  <div class="intro__content"></div>
  <div class="intro__footer"></div>
</section>
<section class="section section--blue" style="border-bottom: thin solid white">
  <div class="section__content">
    Landeshauptstadt München<br/>
    80313 München<br/>
    rathaus@muenchen.de<br/>
    Telefon: (089) 233-00<br/>
    Fax: (089) 233-26458<br/>
    <br/>
    Oberbürgermeister Dieter Reiter<br/>
    buero.ob@muenchen.de<br/>
    <br/>
    Landeshauptstadt München<br/>
    Körperschaft des öffentlichen Rechts<br/>
    <br/>
    Umsatzsteuer-Identifikationsnummer: DE 129 524 000<br/>
    <br/>
    Zuständige Aufsichtsbehörde: Regierung von Oberbayern<br/>
    <br/>
    Vertretungsberechtigt und verantwortlich für den Inhalt:<br/>
    <br/>
    Referat für Gesundheit und Umwelt<br/>
    Bayerstraße 28a<br/>
    80335 München<br/>
    rgu@muenchen.de<br/>
    Telefon: (089) 233-96300<br/>
    Fax: (089) 233-47305<br/>
    <br/>
    Konzeption, Text: "TUM: Junge Akademie" der Technischen Universität München, Team Munich Urban Climate Trail: Beate Neu, Nitish Nagesh, Jessica Neusser, Niklas Dreymann, Johaina Kullab<br/>
    Technische Umsetzung Website: Portal München Betriebs-GmbH & Co. KG, <a href="https://www.webservices.portalmuenchen.de" target="_blank">www.webservices.portalmuenchen.de</a><br/>

  </div>
  <div class="wave">
    <svg preserveAspectRatio="none" style="height: 100%; width: 100%;" viewBox="0 0 500 150">
      <path class="wave-path" d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>
    </svg>
  </div>
</section>
