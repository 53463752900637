<ng-container *ngIf="startpageArticle !== undefined">

  <section [style.backgroundPosition]="'center center'"
           [style.backgroundRepeat]="'no-repeat'"
           [style.backgroundSize]="'cover'"
           [style.backgroundImage]="'url(https://cdn.muenchen-p.de/image/fetch/c_lfill,w_500/'+startpageArticle.media.url+')'"
           class="section section--intro intro-1">
    <div class="intro__head">
      <div class="breadcrumb" style="top: 30px">
      </div>

      <button *ngIf="isMobileDevice" class="orange" routerLink="/interaktive-map">Naturlehrpfad starten</button>
    </div>
    <div class="intro__content"></div>
    <div class="intro__footer"></div>
    <div *ngIf="startpageArticle.media.copyright != null" class="copyright">
      {{startpageArticle.media.copyright}}
    </div>

    <a (click)="onAnkerClick()" class="anker-link">
      <span class="icon icon--medium arrow-down"></span>
    </a>

  </section>


  <section class="section section--white" id="start">

    <div [innerHTML]="startpageArticle.description" class="section__content"></div>

    <div class="wave">
      <svg preserveAspectRatio="none" style="height: 100%; width: 100%;" viewBox="0 0 500 150">
        <path class="wave-path" d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>
      </svg>
    </div>
  </section>

</ng-container>
