import {Component, Input, OnInit} from '@angular/core';
import {SharingService} from "../../services/system/sharing.service";

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  @Input() quizData: any;

  quizPageActive: number = 1;

  quizPageAnsweredActive: boolean = false;
  quizPageAnsweredRight: boolean = false;
  quizPageAnsweredWrong: boolean = false;
  selectedAnswers = [];

  totalQuestions = 0;
  rightAnswers = 0;

  constructor(private sharingService: SharingService) {
  }

  ngOnInit() {
    this.totalQuestions = this.quizData.data.content.length;
  }

  onAnswerSelected(event) {
    let selectedAnswer = event.target,
      allAnswers = <HTMLElement>selectedAnswer.closest('.aselect');

      if(this.selectedAnswers.indexOf(selectedAnswer.value) >= 0)
      {
        this.selectedAnswers.splice(this.selectedAnswers.indexOf(selectedAnswer.value), 1);
      }else {
        this.selectedAnswers.push(selectedAnswer.value);
      }
  }

  rangeSlider(event) {
    let slider = event.target;
    let bubble = document.getElementById('bubble');

    bubble.innerHTML = slider.value;
  };

  onSliderCheck(event) {
    let clickedAnswer = event.target,
      parentQuizPage = <HTMLElement>clickedAnswer.closest('.quiz__page');
    let isTrue = false;
    let slider = <HTMLInputElement>document.getElementById('rangeSlider');

    if(slider.value == parentQuizPage.dataset.correctanswer)
    {
      isTrue = true;
    }

    if (parentQuizPage !== undefined && parentQuizPage !== null) {

      if (isTrue) {
        this.quizPageAnsweredActive = true;

        this.quizPageAnsweredRight = true;
        this.quizPageAnsweredWrong = false;

        this.rightAnswers++;
      } else {
        this.quizPageAnsweredActive = true;

        this.quizPageAnsweredRight = false;
        this.quizPageAnsweredWrong = true;
      }
    }
  }

  onAnswerClicked(event) {
    let clickedAnswer = event.target,
      parentQuizPage = <HTMLElement>clickedAnswer.closest('.quiz__page');

    let sortedArray = parentQuizPage.dataset.correctanswer.split(',').sort();


    var that = this;
    var isTrue = sortedArray.length === this.selectedAnswers.length && sortedArray.sort().every(function(value, index) { return value === that.selectedAnswers.sort()[index]});

    if (parentQuizPage !== undefined && parentQuizPage !== null) {

      if (isTrue) {
        this.quizPageAnsweredActive = true;

        this.quizPageAnsweredRight = true;
        this.quizPageAnsweredWrong = false;
        this.rightAnswers++;
      } else {
        this.quizPageAnsweredActive = true;

        this.quizPageAnsweredRight = false;
        this.quizPageAnsweredWrong = true;
      }
    }

    this.selectedAnswers = [];
    sortedArray = null;
  }

  onNextQuestionClick() {
    this.quizPageActive++;
    this.quizPageAnsweredActive = false;
    this.quizPageAnsweredRight = false;
    this.quizPageAnsweredWrong = false;
  }

  onClosePopup() {
    let sharingData = {
      quizPopupClosed: true
    };
    this.sharingService.emitChange(sharingData);
  }

}
