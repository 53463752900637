import {Injectable} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";
import {AbstractControl} from "@angular/forms";

@Injectable()
export class SystemService {

  public static metatagService: Meta;
  public static pageTitleService: Title;

  constructor(private metatagService: Meta,
              private pageTitleService: Title) {
    SystemService.metatagService = metatagService;
    SystemService.pageTitleService = pageTitleService;
  }

  public static checkIsLeapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
  }

  public static formatNumberToDecimalString(value) {
    return value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  }

  public static sumArrayObjectValues(array, propertyname = null) {
    if (array == null) {
      return 0;
    }

    if (propertyname === null) {
      return array.reduce(function (a, b) {
        return b == null ? a : a + b;
      }, 0);
    }

    return array.reduce(function (a, b) {
      return b[propertyname] == null ? a : a + b[propertyname];
    }, 0);
  }

  public static returnObjectViaProperty(arrayItem, property, value) {
    return arrayItem.find(item => item[property] === value);
  }

  public static resetArrayObjectValuesTo(arrayItem, property, value) {
    for (let i = 0; i < arrayItem.length; i++) {
      if (arrayItem[i].hasOwnProperty(property)) {
        arrayItem[i][property] = value;
      }
    }

    return arrayItem;
  }

  public static insertObjectAtSpecificIndex(array, index, item, itemIndex) {
    array.splice(itemIndex, 1);
    array.splice(index, 0, item);
    return array;
  }

  public static removeItemInArrayAtSpecificIndex(array, item) {
    let index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  }

  public static setUrlHash(value) {
    if (isPlatformBrowser('browser')) {
      window.location.hash = '#' + value;
    }
  }

  public static removeUrlHash() {
    if (isPlatformBrowser('browser')) {
      let scrollV, scrollH, loc = window.location;
      if ('pushState' in history) {
        history.pushState('', document.title, loc.pathname + loc.search);
      } else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;
        loc.hash = '';
        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
      }
    }
  }

  public static updateMetatags(componentName, metatagTexts) {
    if (metatagTexts !== undefined) {
      // change title
      SystemService.pageTitleService.setTitle(metatagTexts[componentName].title);
      // description
      SystemService.metatagService.updateTag({
        name: "description",
        content: metatagTexts[componentName].description
      });
      // facebook
      SystemService.metatagService.updateTag({
        property: "og:title",
        content: metatagTexts[componentName].title
      });
      SystemService.metatagService.updateTag({
        property: "og:site_name",
        content: metatagTexts[componentName].ogSitename
      });
      SystemService.metatagService.updateTag({
        property: "og:url",
        content: metatagTexts[componentName].ogUrl
      });
      SystemService.metatagService.updateTag({
        property: "og:description",
        content: metatagTexts[componentName].description
      });
      // twitter
      SystemService.metatagService.updateTag({
        name: "twitter:title",
        content: metatagTexts[componentName].title
      });
      SystemService.metatagService.updateTag({
        name: "twitter:description",
        content: metatagTexts[componentName].description
      });
    }
  }

  public static checkUsedBrowser() {
    if (isPlatformBrowser('browser')) {
      let ua = window.navigator.userAgent,
        msie = ua.indexOf('MSIE '), // IE 10 or older
        trident = ua.indexOf('Trident/'), // IE 11
        edge = ua.indexOf('Edge/'); // Edge (IE 12+)
      if (msie > 0 || trident > 0 || edge > 0) {
        document.getElementsByTagName('body')[0].classList.add('internetExplorer');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('internetExplorer');
      }
    }
  }

  public static checkDeviceType(platformId: any) {
    if (isPlatformBrowser(platformId)) {
      if (navigator !== undefined) {
        //return /iPhone|iPad|iPod|Android|Mobi/i.test(navigator.userAgent) || window.matchMedia("(max-width: 800px)").matches;
        return window.matchMedia("(max-width: 900px)").matches;
      }
    }
    return false;
  }

  public static returnDateToday() {
    let dateToday = new Date;
    return this.formatDate(dateToday, 1);
  }

  public static formatDate(date: Date, mode = 1) {
    let dateFormatted,
      day = date.getDate(),
      dayString = day.toString(),
      month = date.getMonth() + 1,
      monthString = month.toString(),
      year = date.getFullYear();

    if (day < 10) {
      dayString = '0' + dayString;
    }

    if (month < 10) {
      monthString = '0' + month.toString();
    }

    switch (mode) {
      case 1: // dd-mm-yyyy
        dateFormatted = dayString + '-' + monthString + '-' + year;
        break;
      case 2: // dd/mm/yyyy
        dateFormatted = dayString + '/' + monthString + '/' + year;
        break;
      case 3: // dd.mm.yyyy
        dateFormatted = dayString + '.' + monthString + '.' + year;
        break;
      case 4: // MM/DD/YYYY
        dateFormatted = monthString + '/' + dayString + '/' + year;
        break;
    }

    return dateFormatted;
  }

  public static isEmptyArray(array) {
    return array.length === 0 || array === null;
  }

  public static isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  public static replaceInString(string, toReplace, newText) {
    let o = string.toString(),
      r = '',
      s = o,
      b = 0,
      e = -1;

    while ((e = s.indexOf(toReplace)) > -1) {
      r += o.substring(b, b + e) + newText;
      s = s.substring(e + toReplace.length, s.length);
      b += e + toReplace.length;
    }

    // Add Leftover
    if (s.length > 0) {
      r += o.substring(o.length - s.length, o.length);
    }

    // Return New String
    return r;
  }

  public static ConfirmPassword(AC: AbstractControl) {
    let password = AC.get('userPass').value,
      confirmPassword = AC.get('userPassConfirm').value;
    if (password != confirmPassword) {
      AC.get('userPassConfirm').setErrors({ConfirmPassword: true});
    } else {
      return null;
    }
  }

}
