<div *ngIf="quizData !== undefined && quizData !== null" class="quiz__wrapper">
  <ng-container *ngIf="quizData.data.hasOwnProperty('content')">
    <ng-container *ngFor="let questionItem of quizData.data.content; let index = index">

      <ng-container *ngIf="questionItem.type == 'step'">
        <div *ngIf="quizPageActive === index+1"
             [attr.data-correctanswer]="questionItem.correct_option"
             class="quiz__page">
          <div *ngIf="questionItem.media !== null" [style.backgroundPosition]="'center center'"
               [style.backgroundRepeat]="'no-repeat'"
               [style.backgroundSize]="'cover'"
               [style.background]="'url(https://cdn.muenchen-p.de/image/fetch/c_lfill,w_500/'+ questionItem.media.url +')'"
               class="quiz__teaser" style="background-size: cover; background-repeat: no-repeat">

            <span [innerHTML]="quizData.name"></span>
            <span [innerHTML]="index+1 + '/' + quizData.data.content.length"></span>
          </div>

          <!--  -->
          <div *ngIf="questionItem.media == null" class="quiz__teaser no-image" style="height: 85px">
            <span [innerHTML]="quizData.name" style="top:30px;"></span>
            <span [innerHTML]="index+1 + '/' + quizData.data.content.length" style="top:30px;"></span>
          </div>

          <div *ngIf="questionItem.media !== null" class="wave__warpper">
            <div class="wave">
              <svg preserveAspectRatio="none" style="height: 100%; width: 100%;" viewBox="0 0 500 150">
                <path class="wave-path"
                      d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>
              </svg>
            </div>
          </div>

          <div *ngIf="!quizPageAnsweredActive" class="quiz__options">
            <div [innerHTML]="questionItem.question" class="desc"></div>
            <input (change)="onAnswerSelected($event)"
                   [id]="'q_'+index+'_a_1'"
                   [name]="'q_'+index+'answer'"
                   type="checkbox"
                   value="1">
            <label [for]="'q_'+index+'_a_1'" [innerHTML]="questionItem.option_1"></label>
            <input (change)="onAnswerSelected($event)"
                   [id]="'q_'+index+'_a_2'"
                   [name]="'q_'+index+'answer'"
                   type="checkbox"
                   value="2">
            <label [for]="'q_'+index+'_a_2'" [innerHTML]="questionItem.option_2"></label>

            <input (change)="onAnswerSelected($event)"
                   *ngIf="questionItem.option_3 != null"
                   [id]="'q_'+index+'_a_3'"
                   [name]="'q_'+index+'answer'"
                   type="checkbox"
                   value="3">
            <label *ngIf="questionItem.option_3 != null" [for]="'q_'+index+'_a_3'" [innerHTML]="questionItem.option_3"></label>

            <input (change)="onAnswerSelected($event)"
                   *ngIf="questionItem.option_4 != null"
                   [id]="'q_'+index+'_a_4'"
                   [name]="'q_'+index+'answer'"
                   type="checkbox"
                   value="4">
            <label *ngIf="questionItem.option_4 != null" [for]="'q_'+index+'_a_4'" [innerHTML]="questionItem.option_4"></label>

            <div class="btn aselect" (click)="onAnswerClicked($event)">Weiter</div>
          </div>

          <div *ngIf="quizPageAnsweredActive" class="quiz__answer">
            <div *ngIf="quizPageAnsweredRight" class="headline correct">Richtig!</div>
            <div *ngIf="quizPageAnsweredWrong" class="headline false">Falsch!</div>
            <p [innerHTML]="questionItem.answer" class="desc"></p>
            <p class="desc" *ngIf="quizPageActive === quizData.data.content.length">Korrekte Antworten: {{rightAnswers}} / {{totalQuestions}}</p>
            <div (click)="onNextQuestionClick()" *ngIf="quizPageActive < quizData.data.content.length" class="btn">Weiter</div>
            <div (click)="onClosePopup()" *ngIf="quizPageActive === quizData.data.content.length" class="btn">Schließen</div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="questionItem.type == 'slider'">
        <div *ngIf="quizPageActive === index+1"
             [attr.data-correctanswer]="questionItem.answer"
             class="quiz__page">
          <div *ngIf="questionItem.media !== null" [style.backgroundPosition]="'center center'"
               [style.backgroundRepeat]="'no-repeat'"
               [style.backgroundSize]="'cover'"
               [style.background]="'url(https://cdn.muenchen-p.de/image/fetch/c_lfill,w_500/'+ questionItem.media.url +')'"
               class="quiz__teaser">

            <span [innerHTML]="quizData.name"></span>
            <span [innerHTML]="index+1 + '/' + quizData.data.content.length"></span>
          </div>

          <!--  -->
          <div *ngIf="questionItem.media == null" class="quiz__teaser no-image" style="height: 85px">
            <span [innerHTML]="quizData.name" style="top:30px;"></span>
            <span [innerHTML]="index+1 + '/' + quizData.data.content.length" style="top:30px;"></span>
          </div>

          <div *ngIf="questionItem.media !== null" class="wave__warpper">
            <div class="wave">
              <svg preserveAspectRatio="none" style="height: 100%; width: 100%;" viewBox="0 0 500 150">
                <path class="wave-path"
                      d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>
              </svg>
            </div>
          </div>

          <div *ngIf="!quizPageAnsweredActive" class="quiz__options">
            <div [innerHTML]="questionItem.question" class="desc"></div>
            <div class="ranger-slider">
              <input (input)="rangeSlider($event)" id="rangeSlider" style="width: 100%; height: 60px" class="ranger-slider__range" type="range" [value]="questionItem.min" [min]="questionItem.min" [max]="questionItem.max" [step]="questionItem.interval" name="rangeSlider">
              <div id="bubble" style="text-align: center;font-size: 26px">{{questionItem.min}}</div>
            </div>
            <div class="btn aselect" (click)="onSliderCheck($event)">Weiter</div>
          </div>

          <div *ngIf="quizPageAnsweredActive" class="quiz__answer">
            <div *ngIf="quizPageAnsweredRight" class="headline correct">Richtig!</div>
            <div *ngIf="quizPageAnsweredWrong" class="headline false">Falsch!</div>
            <p class="desc">Die korrekte Antwort lautet {{questionItem.answer}}</p>
            <p class="desc" *ngIf="quizPageActive === quizData.data.content.length">Korrekte Antworten: {{rightAnswers}} / {{totalQuestions}}</p>
            <div (click)="onNextQuestionClick()" *ngIf="quizPageActive < quizData.data.content.length" class="btn">Weiter</div>
            <div (click)="onClosePopup()" *ngIf="quizPageActive === quizData.data.content.length" class="btn">Schließen</div>
          </div>
        </div>
      </ng-container>

    </ng-container>


  </ng-container>
</div>
