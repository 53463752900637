import {Component, OnInit} from '@angular/core';
import {SearchApiService} from "../services/search-api/search-api.service";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  results: any;

  constructor(
    private SearchApiService: SearchApiService,
    private router: Router,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.setTitle('Suche - Munich Urban Climate Trail');
  }

  onKey(event) {
    let val = event.target.value;

    if (val.length > 3) {
      this.SearchApiService.getResults(event.target.value).subscribe((data) => {
        this.results = data;
      });
    } else {
      this.results = [];
    }
  }

  onStationSelectClick(event) {
    let clickedStation = <HTMLElement>event.target,
      stationId = clickedStation.dataset.stationid;

    if (clickedStation !== undefined && clickedStation !== null) {
      this.router.navigate(['/article'], {queryParams: {stationid: stationId}});
    }
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  onModuleSelectClick(event) {
    let clickedStation = <HTMLElement>event.target,
      stationId = clickedStation.dataset.stationid,
      moduleId = clickedStation.dataset.moduleid;

    if (clickedStation !== undefined && clickedStation !== null) {
      this.router.navigate(['/article'], {queryParams: {stationid: stationId, moduleid: moduleId}});
    }
  }

}
