import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {StartpageComponent} from "./startpage/startpage.component";
import {HeaderComponent} from "./header/header.component";
import {SwiperConfigInterface, SwiperModule} from "ngx-swiper-wrapper";
import {FeedbackFormService} from "./services/feedback/feedbackform.service";
import {ArticleComponent} from "./content/article/article.component";
import {SearchApiService} from "./services/search-api/search-api.service";
import {InfoComponent} from "./info/info.component";
import {QuizPopupComponent} from "./content/quiz/quiz-popup/quiz-popup.component";
import {FooterComponent} from "./footer/footer.component";
import {MainnavComponent} from "./mainnav/mainnav.component";
import {WuermOverviewComponent} from "./content/wuerm-overview/wuerm-overview.component";
import {MapBoxComponent} from "./map-box/map-box.component";
import {FeedbackComponent} from "./feedback/feedback.component";
import {EchtzeitproxyService} from "./services/echtzeitproxy/echtzeitproxy.service";
import {SearchComponent} from "./search/search.component";
import {GalleryPopupComponent} from "./gallery-popup/gallery-popup.component";
import {MapBoxPageviewComponent} from "./content/map-box-pageview/map-box-pageview.component";
import {ArticlePopupComponent} from "./content/article/article-popup/article-popup.component";
import {ImprintComponent} from "./imprint/imprint.component";
import {DateprotectionComponent} from "./dateprotection/dateprotection.component";
import {QuizComponent} from "./content/quiz/quiz.component";
import {ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    StartpageComponent,
    HeaderComponent,
    MainnavComponent,
    WuermOverviewComponent,
    MapBoxComponent,
    MapBoxPageviewComponent,
    ArticleComponent,
    SearchComponent,
    ArticlePopupComponent,
    FooterComponent,
    FeedbackComponent,
    QuizComponent,
    QuizPopupComponent,
    FeedbackComponent,
    InfoComponent,
    GalleryPopupComponent,
    ImprintComponent,
    DateprotectionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // Swiper
    SwiperModule,
    ReactiveFormsModule
  ],
  providers: [
    SearchApiService,
    FeedbackFormService,
    EchtzeitproxyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
