<section class="section section--intro type-teaser" style="background-color: #223E6D !important;height:200px">
  <div class="intro__head" style="top:">
    <h1>Datenschutzerklärung und Haftungsausschluss</h1>
  </div>
  <div class="intro__content"></div>
  <div class="intro__footer"></div>
</section>
<section class="section section--blue" style="border-bottom: thin solid white">
  <div class="section__content">
    <b>Datenschutzerklärung</b><br/><br/>
    §1 Information über die Erhebung personenbezogener Daten<br/>
    <br/>
    (1) Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung unserer Website. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.<br/>
    <br/>
    (2) Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DS-GVO) ist die Landeshauptstadt München (Referat für Gesundheit und Umwelt, Bayerstraße 28a, 80335 München (siehe unser Impressum).<br/>
    <br/>
    Unseren Datenschutzbeauftragten erreichen Sie unter:<br/>
    Landeshauptstadt München<br/>
    Behördlicher Datenschutzbeauftragter<br/>
    Burgstr. 4<br/>
    80331 München<br/>
    E-Mail: datenschutz@muenchen.de<br/>
    <br/>
    (3) Bei Ihrer Kontaktaufnahme mit uns per E-Mail werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.<br/>
    <br/>
    (4) Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen, werden wir Sie untenstehend im Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.<br/>
    <br/>
    (5) Allgemeine Informationen zu Einwilligungen<br/>
    Soweit Sie uns eine Einwilligung zur Verarbeitung personenbezogener Daten für bestimmte, weitere Zwecke erteilt haben (z.B. Übermittlung der Standortdaten), erfolgt diese Datenverarbeitung auf Basis dieser Einwilligung. Details zu den Inhalten einer Einwilligung stellen wir bei Abfrage der Einwilligung zur Verfügung.<br/>
    <br/>
    <br/>
    §2 Ihre Rechte<br/>
    <br/>
    (1) Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:<br/>
    – Recht auf Auskunft,<br/>
    – Recht auf Berichtigung oder Löschung,<br/>
    – Recht auf Einschränkung der Verarbeitung,<br/>
    – Recht auf Widerspruch gegen die Verarbeitung,<br/>
    – Recht auf Datenübertragbarkeit.<br/>
    <br/>
    (2) Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.<br/>
    <br/>
    <br/>
    §3 Erhebung personenbezogener Daten bei Besuch unserer Website<br/>
    <br/>
    (1) Bei der Nutzung der Website erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website nutzen möchten, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO):<br/>
    <br/>
    – IP-Adresse<br/>
    – Standortdaten<br/>
    – Datum und Uhrzeit der Anfrage<br/>
    – Zeitzonendifferenz zur Greenwich Mean Time (GMT)<br/>
    – Inhalt der Anforderung (konkrete Seite)<br/>
    – Zugriffsstatus/HTTP-Statuscode<br/>
    – jeweils übertragene Datenmenge<br/>
    – Website, von der die Anforderung kommt<br/>
    – Browser<br/>
    – Betriebssystem und dessen Oberfläche<br/>
    – Sprache und Version der Browsersoftware.<br/>
    <br/>
    <br/>
    §4 Weitere Funktionen und Angebote unserer Website<br/>
    <br/>
    (1) Neben der rein informatorischen Nutzung unserer Website bieten wir verschiedene Leistungen an, die Sie bei Interesse nutzen können. Dazu müssen Sie in der Regel weitere personenbezogene Daten angeben, die wir zur Erbringung der jeweiligen Leistung nutzen und für die die zuvor genannten Grundsätze zur Datenverarbeitung gelten.<br/>
    <br/>
    (2) Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer Dienstleister. Diese wurden von uns sorgfältig ausgewählt und beauftragt, sind an unsere Weisungen gebunden und werden regelmäßig kontrolliert.<br/>
    <br/>
    (3) Soweit unsere Dienstleister oder Partner ihren Sitz in einem Staat außerhalb des Europäischen Wirtschaftsraumes (EWR) haben, informieren wir Sie über die Folgen dieses Umstands in der Beschreibung des Angebotes.<br/>
    <br/>
    (4) Auf der Webseite werden Funktionen des Dienstes Mapbox verwendet. Diese Funktionen werden angeboten durch das Unternehmen mapbox.com. Durch die Benutzung von Mapbox werden Daten an Mapbox übertragen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Mapbox erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Mapbox unter https://www.mapbox.com/privacy/<br/>
    <br/>
    § 4a Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten<br/>
    <br/>
    <br/>
    (1) Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit widerrufen. Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben.<br/>
    <br/>
    (2) Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen, können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns
    durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.<br/>
    <br/>
    <br/>
    §5 Hosting<br/>
    <br/>
    (1) Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen.<br/>
    Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).<br/>
    <br/>
    (2) Ihre personenbezogenen Daten werden von unserem Dienstleister der Portal München Betriebs-GmbH & Co. KG auf einem besonders geschützten Server in der Europäischen Union gespeichert. Wir sind um alle notwendigen, technischen und organisatorischen Sicherheitsmaßnahmen bemüht, um Ihre personenbezogenen Daten so zu speichern, dass sie vor unberechtigtem Zugang und Missbrauch geschützt sind.<br/>
    <br/>
    <br/>
    § 6 Änderung der Datenschutzerklärung<br/>
    <br/>
    Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.<br/>
    <br/>
    <br/>

    <b>Haftungsausschluss</b><br/><br/>
    Das Referat für Gesundheit und Umwelt verwendet für diese Web-App Informationen, die mit größter Sorgfalt in die Web-App integriert werden. Eine Gewährleistung für die Vollständigkeit, Verfügbarkeit, Aktualität und Richtigkeit dieser Daten kann jedoch nicht übernommen werden.
    <br/><br/>
    Das Referat für Gesundheit und Umwelt haftet nicht für Schäden, die durch die Nutzung dieser App entstehen. Dieser Haftungsausschluss gilt nicht, soweit die Vorschriften des § 839 BGB einschlägig sind.
    <br/><br/>
    Diese Web-App enthält auch Links zu Webseiten, die von Dritten betrieben werden. Das Referat für Gesundheit und Umwelt ist für die Inhalte der durch die Links erreichbaren Seiten nicht verantwortlich. Für diese Inhalte und insbesondere Schäden haftet allein der jeweilige Anbieter der Seite.

  </div>
  <div class="wave">
    <svg preserveAspectRatio="none" style="height: 100%; width: 100%;" viewBox="0 0 500 150">
      <path class="wave-path" d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>
    </svg>
  </div>
</section>
