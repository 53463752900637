<ng-container *ngIf="viewmode === 0">
  <nav class="nav__desktop">
    <div class="mainnav__content">
      <section>
        <span class="label">Munich Urban Climate Trail</span>
        <ul>
          <li><a (click)="onMenuLinkClicked()" routerLink="">Startseite</a></li>
          <li><a (click)="onMenuLinkClicked()" routerLink="app-uebersicht">Der Munich Urban Climate Trail im Überblick</a></li>
          <li><a (click)="onMenuLinkClicked()" routerLink="suche">Suche</a></li>
          <li><a (click)="onMenuLinkClicked()" routerLink="feedback">Feedback zur App</a></li>
          <li><a (click)="onMenuLinkClicked()" routerLink="info">Quellen & weiterführende Literatur</a></li>
        </ul>
      </section>
      <section>
        <span class="label">Links</span>
        <ul>
          <li><a (click)="onMenuLinkClicked()" routerLink="impressum" routerLinkActive="active">Impressum</a></li>
          <li><a (click)="onMenuLinkClicked()" routerLink="datenschutz" routerLinkActive="active">Datenschutzerklärung und Haftungsausschluss</a></li>
        </ul>
      </section>
      <section>
        <a href="https://www.muenchen.de">
          <img alt="Landeshauptstadt München" class="desktop" src="../../assets/images/lhm_rgu_logo.png"/>
        </a>
      </section>
    </div>
  </nav>
</ng-container>

<ng-container *ngIf="viewmode === 1 || viewmode === 2">
  <nav class="mainnav" id="mainnav" style="display: block;">
    <div class="mainnav__content">
      <section>
        <span class="label">Munich Urban Climate Trail</span>
        <ul>
          <li><a (click)="onMenuLinkClicked()" routerLink="">Startseite</a></li>
          <li *ngIf="isMobileDevice"><a (click)="onMenuLinkClicked()" routerLink="interaktive-map"
                                        routerLinkActive="active">Interaktive Karte</a></li>
          <li><a (click)="onMenuLinkClicked()" routerLink="app-uebersicht">Der Munich Urban Climate Trail im Überblick</a>
          </li>
          <li><a (click)="onMenuLinkClicked()" routerLink="suche">Suche</a></li>
          <li><a (click)="onMenuLinkClicked()" routerLink="feedback">Feedback zur App</a></li>
          <li><a (click)="onMenuLinkClicked()" routerLink="info">Quellen & weiterführende Literatur</a></li>
        </ul>
      </section>
      <section>
        <span class="label">Links</span>
        <ul>
          <li><a (click)="onMenuLinkClicked()" routerLink="impressum" routerLinkActive="active">Impressum</a></li>
          <li><a (click)="onMenuLinkClicked()" routerLink="datenschutz" routerLinkActive="active">Datenschutzerklärung und <br/>Haftungsausschluss</a></li>
        </ul>
        <a href="https://www.muenchen.de"><img alt="Landeshauptstadt München"
                                               src="../../assets/images/lhm_rgu_logo.png"/></a>
      </section>
      <div class="wave">
        <svg preserveAspectRatio="none" style="height: 100%; width: 100%;" viewBox="0 0 500 150">
          <path class="wave-path"
                d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>
        </svg>
      </div>
    </div>
  </nav>
</ng-container>
