import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  TRAIL_ID = 2;
  TOKEN = '1qayxsw23edcvfr45tgb09ijnhz7';
  URL_STATIONS_GEO = 'https://backend-muctrail.wuermentdecken.de/api/2/stations.json';
  URL_STATIONS_OVERVIEW = 'https://backend-muctrail.wuermentdecken.de/api/2/stationsOverview.json';
  URL_STATION_SINGLE = 'https://backend-muctrail.wuermentdecken.de/api/station/';
  URL_LEHRPFAD_DETAIL = 'https://backend-muctrail.wuermentdecken.de/api/trail/';
  URL_TRACKS = 'https://backend-muctrail.wuermentdecken.de/api/2/tracks.json';
  URL_INFORMATIONS = 'https://backend-muctrail.wuermentdecken.de/api/informations.json';

  contentAll = {
    'stations': [],
    'stationsOverview': [],
    'startpage': [],
    'tracks': [],
    'informations': [],
    'wuermranger': []
  };

  constructor(private http: HttpClient,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  getStationGeoAll() {

    let httpOptions = {
      //headers: new HttpHeaders().set('Authorization', 'Basic'),
      params: new HttpParams().set('token', '1qayxsw23edcvfr45tgb09ijnhz7'),
    };

    return this.http.get(this.URL_STATIONS_GEO, httpOptions);
  }

  getStationOverviewAll() {

    let httpOptions = {
      //headers: new HttpHeaders().set('Authorization', 'Basic'),
      params: new HttpParams().set('token', '1qayxsw23edcvfr45tgb09ijnhz7'),
    };


    return this.http.get(this.URL_STATIONS_OVERVIEW, httpOptions);
  }

  getStationSingle(id: number) {
    let url = this.URL_STATION_SINGLE + id + '.json?token=' + this.TOKEN;
    return this.http.get(url);
  }

  getLehrpfadDetailSingle(id: number) {
    let url = this.URL_LEHRPFAD_DETAIL + id + '.json?token=' + this.TOKEN;
    return this.http.get(url);
  }

  getTracksAll() {
    let url = this.URL_TRACKS + '?token=' + this.TOKEN;
    return this.http.get(url);
  }

  getInformationsAll() {
    let url = this.URL_INFORMATIONS + '?token=' + this.TOKEN;
    return this.http.get(url);
  }

  getWuermrangerAll() {
    let url = 'https://www.wuermentdecken.de/assets/wr.json';
    return this.http.get(url);
  }

  getDataAll() {
    this.getStationGeoAll().subscribe((result: any) => {
      this.contentAll.stations = result;

      this.getLehrpfadDetailSingle(2).subscribe((result: any) => {
        this.contentAll.startpage = result;

        this.saveContentAllInStorage();
      });
    });

    this.getTracksAll().subscribe((result: any) => {
      this.contentAll.tracks = result;
      this.saveContentAllInStorage();
    })

    this.getStationOverviewAll().subscribe((result: any) => {
      this.contentAll.stationsOverview = result;
      this.saveContentAllInStorage();
    })

    this.getInformationsAll().subscribe((result: any) => {
      this.contentAll.informations = result;
      this.saveContentAllInStorage();
    });

    this.getWuermrangerAll().subscribe((result: any) => {
      this.contentAll.wuermranger = result;
      this.saveContentAllInStorage();
    });
  }

  loadContentAll() {
    if (isPlatformBrowser(this.platformId)) {
      let storageObj = JSON.parse(window.sessionStorage.getItem('ct'));

      if (storageObj !== null) {
        this.contentAll = storageObj;
      } else if (storageObj === null) {
        this.getDataAll();
      }
    }
  }

  saveContentAllInStorage() {
    window.sessionStorage.setItem('ct', JSON.stringify(this.contentAll));
  }
}
