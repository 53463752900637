<section class="section section--intro type-teaser" style="background-color: #223E6D !important;height:120px">
  <div class="intro__head">
    <h1>Suche</h1>
  </div>
  <div class="intro__content"></div>
  <div class="intro__footer"></div>
</section>

<section class="section section--blue" style="border-bottom: thin solid white">
  <div class="section__content">
    <p>
      Auf dieser Seite kannst du alle Inhalte des Munich Urban Climate Trail durchsuchen. Tippe einfach den Begriff, z.B. Fauna,
      der dich interessiert in das Suchfeld ein.
    </p>
    <br/>
    <input (keyup)="onKey($event)" placeholder="z.B. Fauna"
           style="background-color:#dfe3ef; width: 100%; font-size: 17pt; font-weight: bold; border-bottom: thin solid #223E6D; border-right: none;border-left: none;border-top: none"
           type="text"/>

  </div>
  <div class="wave">
    <svg preserveAspectRatio="none" style="height: 100%; width: 100%;" viewBox="0 0 500 150">
      <path class="wave-path" d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>
    </svg>
  </div>
</section>

<section *ngIf="results !== undefined && results !== null && results.length == 0" class="section section--result">
  <div class="section__content">
    <h3>Keine Ergebnisse</h3>
  </div>
</section>

<section *ngFor="let result of results" class="section section--result">
  <div (click)="onStationSelectClick($event)"
       *ngIf="result.type === 'station'"
       [attr.data-stationid]="result.station_id"
       class="section__content">
    <h3>{{ result.name }}</h3>
  </div>
  <div (click)="onModuleSelectClick($event)"
       *ngIf="result.type === 'module'"
       [attr.data-moduleid]="result.module_id"
       [attr.data-stationid]="result.station_id"
       class="section__content">
    <h3>{{ result.name }}</h3>
  </div>
</section>
