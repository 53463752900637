<ng-container *ngIf="articleItem !== undefined && articleItem !== null" xmlns="http://www.w3.org/1999/html">
  <div class="article article__wrapper">
    <div class="article__header">
      <!-- Teaserbild -->
      <section class="section section--intro type-teaser article__teaser">

        <ng-container *ngIf="articleItem.hasOwnProperty('medias') && articleItem.medias.length > 1">
          <div class="module__media media__wrapper header">
            <swiper (indexChange)="onIndexChange($event)"
                    [(index)]="imageActiveIndex"
                    [config]="configSwiper"
                    [disabled]="articleItem.medias.length === 1">
              <ng-container *ngFor="let mediaItem of articleItem.medias">
                <div (click)="onMediaItemClick($event)"
                     [attr.data-moduleindex]="'station'"
                     [style.backgroundImage]="'url(https://cdn.muenchen-p.de/image/fetch/c_lfill,w_930/'+ mediaItem.url+')'"
                     [style.backgroundRepeat]="'no-repeat'"
                     [style.backgroundSize]="'cover'"
                     class="media__item header">
                  <span *ngIf="mediaItem.copyright != null" class="copyright header">{{mediaItem.copyright}}</span>
                </div>
              </ng-container>
            </swiper>
          </div>
        </ng-container>

        <ng-container *ngIf="articleItem.hasOwnProperty('medias') && articleItem.medias.length == 1">
          <div class="module__media media__wrapper header">

                <div [attr.data-moduleindex]="'station'"
                     [style.backgroundImage]="'url(https://cdn.muenchen-p.de/image/fetch/c_lfill,w_930/'+ articleItem.medias[0].url+')'"
                     [style.backgroundRepeat]="'no-repeat'"
                     [style.backgroundSize]="'cover'"
                     class="media__item header">
                  <span *ngIf="articleItem.medias[0].copyright != null" class="copyright header">{{articleItem.medias[0].copyright}}</span>
                </div>
          </div>
        </ng-container>

        <div class="intro__head">
          <h1 [innerHTML]="articleItem.name" style="z-index: 100"></h1>
        </div>

        <div class="intro__content"></div>
        <div class="intro__footer"></div>
        <div class="wave" style="top:auto;bottom:-1px;z-index: 100">
          <svg preserveAspectRatio="none" style="height: 100%; width: 100%;" viewBox="0 0 500 150">
            <path class="wave-path"
                  d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>
          </svg>
        </div>
      </section>
      <div class="article__subtitle">
        <span *ngIf="articleItem.subheadline != null" [innerHTML]="articleItem.subheadline"></span>
      </div>
    </div>

    <div class="article__info">
      <!-- Inhaltsverzeichnis -->
      <ng-container *ngIf="articleItem.hasOwnProperty('modules') && articleItem.modules.length > 0">
        <div class="section article__sections">
          <span>Inhalt</span>
          <ng-container *ngFor="let moduleContent of articleItem.modules; let index = index">
            <div (click)="onAnkerLinkClick($event)"
                 *ngIf="moduleContent.module_type_id !== 6"
                 [attr.data-ankerlink]="'module_'+index">
              <i *ngIf="moduleContent.module_type_id === 1" class="icon icon--xsmall icon--content iknowledge"></i>
              <i *ngIf="moduleContent.module_type_id === 2" class="icon icon--xsmall icon--content iknowledge"></i>
              <i *ngIf="moduleContent.module_type_id === 3" class="icon icon--xsmall icon--content igame"></i>
              <i *ngIf="moduleContent.module_type_id === 4" class="icon icon--xsmall icon--content inature"></i>
              <i *ngIf="moduleContent.module_type_id === 5" class="icon icon--xsmall icon--content inature"></i>

              <i *ngIf="moduleContent.module_type_id === 6" class="icon icon-info"></i>
              <span [innerHTML]="moduleContent.name"></span>
            </div>
          </ng-container>
        </div>
      </ng-container>


      <!-- Station Description -->
      <ng-container *ngIf="articleItem.description !== null">
        <div class="section section--white">
          <div class="module__content">
            <div [ngClass]="{'is-collapsable': articleItem.description.length > 300}" class="desc">
              <div [innerHTML]="articleItem.description"></div>
            </div>

            <ng-container *ngIf="articleItem.description !== null && articleItem.description.length > 300">
              <div (click)="onCollapseTextClick($event)" class="collapse__content">
                <span class="show__more">Mehr anzeigen</span>
                <span class="show__less">Weniger anzeigen</span>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>


      <!-- Specialbox level 0 -->
      <ng-container *ngIf="articleItem.hasOwnProperty('special_box_active') && articleItem.special_box_active === 1">
        <div *ngIf="articleItem.special_box_headline == 'location'" class="section section--yellow specialbox info">
          <div class="article__specialbox">
            <i class="icon icon--section icon--large location"></i>

            <div class="section__headline" style="text-align: center; padding-left: 0 !important; padding-top: 45px">
              <img src="/assets/images/Icon-19-an-deinem-standort.png"/>
            </div>
            <div class="section__content">
              <div [innerHTML]="articleItem.special_box_content"></div>
            </div>
          </div>
        </div>
        <div *ngIf="articleItem.special_box_headline == 'didyouknow'" class="section section--darkblue specialbox info">
          <div class="article__specialbox">
            <i class="icon icon--section icon--large knowledge"></i>

            <div class="section__headline">
              <img src="/assets/images/Icon-21-wusstest-du-01.png"/>
            </div>
            <div class="section__content">
              <div [innerHTML]="articleItem.special_box_content"></div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="article__content">
      <!-- Modul Content -->
      <ng-container *ngIf="articleItem.hasOwnProperty('modules') && articleItem.modules.length > 0">
        <div class="article__modules">
          <ng-container *ngFor="let moduleContent of articleItem.modules; let moduleIndex = index">
            <div [id]="'module_'+moduleIndex" [ngClass]="{
						'section--blue': moduleContent.module_type_id === 1 || moduleContent.module_type_id === 2,
						'section--red': moduleContent.module_type_id === 4 || moduleContent.module_type_id === 5 || moduleContent.module_type_id === 6,
						'section--yellow': moduleContent.module_type_id === 3,


						'characteristics': moduleContent.module_type_id === 1,
						'knowledgeModule': moduleContent.module_type_id === 2,
						'multipleChoice': moduleContent.module_type_id === 3,
						'searchGame': moduleContent.module_type_id === 4,
						'terrainGame': moduleContent.module_type_id === 5,
						'whatHappensIf': moduleContent.module_type_id === 6}" class="section">

              <i *ngIf="moduleContent.module_type_id === 1" class="icon icon--section icon--large pin"></i>

              <!-- Wissensmodul -->
              <i *ngIf="moduleContent.module_type_id === 2" class="icon icon--section icon--large knowledge"></i>

              <i *ngIf="moduleContent.module_type_id === 3" class="icon icon--section icon--large game"></i>

              <i *ngIf="moduleContent.module_type_id === 4" class="icon icon--section icon--large nature"></i>

              <i *ngIf="moduleContent.module_type_id === 5" class="icon icon--section icon--large nature"></i>

              <i *ngIf="moduleContent.module_type_id === 6" class="icon icon--section icon--large attention"></i>


              <!-- Content Header -->
              <div *ngIf="moduleContent.module_type_id === 3" class="module__teaser">
                <img src="/assets/images/Icon-17-teste-dein-wissen.png"/>
              </div>
              <div *ngIf="moduleContent.module_type_id === 6" class="module__teaser">
                <img src="/assets/images/Icon-16-was-passiert-wenn.png"/>
              </div>

              <div class="section__headline blue">
                <h4 *ngIf="moduleContent.module_type_id !== 6" [innerHTML]="moduleContent.name"></h4>
              </div>

              <div class="section__content">
                <!-- Media -->
                <!-- Slider -->
                <ng-container *ngIf="moduleContent.hasOwnProperty('medias') && moduleContent.medias.length > 1">
                  <div class="module__media media__wrapper">
                    <swiper (indexChange)="onIndexChange($event)"
                            [(index)]="imageActiveIndex"
                            [config]="configSwiper"
                            [disabled]="moduleContent.medias.length === 1">
                      <ng-container *ngFor="let mediaItem of moduleContent.medias">
                        <div (click)="onMediaItemClick($event)"
                             [attr.data-moduleindex]="moduleIndex"
                             [style.backgroundPosition]="'center center'"
                             [style.backgroundRepeat]="'no-repeat'"
                             [style.backgroundSize]="'cover'"
                             [style.background]="'url(https://cdn.muenchen-p.de/image/fetch/c_lfill,w_200,h_200/'+ mediaItem.url+')'"
                             class="media__item">
                          <span *ngIf="mediaItem.copyright != null" class="copyright">{{mediaItem.copyright}}</span>
                        </div>
                      </ng-container>
                    </swiper>
                    <div class="medias__hint">
                      <span>Zum Vergrößern anklicken</span>
                    </div>
                  </div>
                </ng-container>

                <!-- Single -->
                <ng-container *ngIf="moduleContent.hasOwnProperty('medias') && moduleContent.medias.length == 1">
                  <div *ngFor="let mediaItem of moduleContent.medias" class="module__media media__wrapper">
                    <img (click)="onMediaItemClick($event)"
                         [attr.data-moduleindex]="moduleIndex"
                         src="https://cdn.muenchen-p.de/image/fetch/c_lfill,w_425/{{mediaItem.url}}"/>
                    <span *ngIf="mediaItem.copyright != null" class="copyright">{{mediaItem.copyright}}</span>
                    <div class="medias__hint">
                      <span>Zum Vergrößern anklicken</span>
                    </div>
                  </div>
                </ng-container>

                <!-- Data -->
                <div class="module__content">
                  <ng-container *ngIf="moduleContent.hasOwnProperty('data')">

                    <!-- Description -->
                    <ng-container *ngIf="moduleContent.data.hasOwnProperty('description')">
                      <div [innerHTML]="moduleContent.data.description"
                           [ngClass]="{'is-collapsable':moduleContent.data.description.length > 350}"
                           class="desc"></div>

                      <ng-container *ngIf="moduleContent.data.description !== null && moduleContent.data.description.length > 350">
                        <div (click)="onCollapseTextClick($event)" *ngIf="moduleContent.module_type_id !== 3" class="collapse__content blue">
                          <span class="show__more">Mehr anzeigen</span>
                          <span class="show__less">Weniger anzeigen</span>
                        </div>
                      </ng-container>

                      <!-- Specialbox level 1 -->
                      <ng-container
                        *ngIf="moduleContent.data.hasOwnProperty('special_box_active') && moduleContent.data.special_box_active === 1">
                        <div *ngIf="moduleContent.data.special_box_headline == 'location'"
                             class="section section--yellow specialbox">
                          <div class="article__specialbox">
                            <i class="icon icon--section icon--large location"></i>

                            <div class="section__headline">
                              <img src="/assets/images/Icon-19-an-deinem-standort.png"/>
                            </div>
                            <div class="section__content">
                              <div [innerHTML]="moduleContent.data.special_box_content"></div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="moduleContent.data.special_box_headline == 'didyouknow'"
                             class="section section--darkblue specialbox">
                          <div class="article__specialbox">
                            <i class="icon icon--section icon--large knowledge"></i>
                            <div class="section__headline">
                              <img *ngIf="moduleContent.data.special_box_headline == 'didyouknow'"
                                   src="/assets/images/Icon-21-wusstest-du-01.png"/>
                            </div>
                            <div class="section__content">
                              <div [innerHTML]="moduleContent.data.special_box_content"></div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>

                    <!-- Tabellenansicht Steckbrief -->
                    <ng-container *ngIf="moduleContent.module_type_id === 1 && moduleContent.data.length > 0">
                      <div class="module__table">

                        <style>

                        </style>
                        <div class="table-wrapper">
                          <ng-container *ngFor="let characterItem of moduleContent.data">
                            <div class="table__element">
                              <span [innerHTML]="characterItem.code + ': '" class="bold"></span>
                              <span [innerHTML]="characterItem.content"></span>
                            </div>
                          </ng-container>
                        </div>

                        <div (click)="onCollapseTextClick($event)" class="collapse__content">
                          <span class="show__more">Mehr anzeigen</span>
                          <span class="show__less">Weniger anzeigen</span>
                        </div>
                      </div>
                    </ng-container>

                    <!-- Multiple Choice -->
                    <ng-container *ngIf="moduleContent.module_type_id === 3">
                      <button (click)="onStartQuizClick($event)"
                              [attr.data-moduleindex]="moduleIndex"
                              class="article blue">Quiz starten
                      </button>
                    </ng-container>

                    <!-- Suchspiel -->
                    <ng-container *ngIf="moduleContent.module_type_id === 4">
                      <ng-container *ngIf="moduleContent.data.hasOwnProperty('instruction')">
                        <div [innerHTML]="moduleContent.data.instruction"></div>
                      </ng-container>
                      <ng-container *ngIf="moduleContent.data.hasOwnProperty('solution')">
                        <button (click)="onShowSolutionClick()" class="article blue">Lösung anzeigen</button>
                        <div *ngIf="showSolution" [innerHTML]="moduleContent.data.solution"></div>
                      </ng-container>
                    </ng-container>

                    <!-- Geländespiel -->
                    <ng-container *ngIf="moduleContent.module_type_id === 5">
                      <ng-container *ngIf="moduleContent.data.hasOwnProperty('instruction')">
                        <div [innerHTML]="moduleContent.data.instruction" [ngClass]="{'is-collapsable': moduleContent.isCollabsableContent}"
                             class="desc"></div>

                        <div (click)="onCollapseTextClick($event)" *ngIf="moduleContent.isCollabsableContent"
                             class="collapse__content">
                          <span class="show__more">Mehr anzeigen</span>
                          <span class="show__less">Weniger anzeigen</span>
                        </div>
                      </ng-container>
                    </ng-container>

                    <!-- Accordion Content -->
                    <ng-container *ngIf="moduleContent.module_type_id === 6 && moduleContent.data.length > 0">
                      <div *ngFor="let accItem of moduleContent.data" class="accordion">
                        <div (click)="onAccordionHeaderClick($event)" class="accordion--header">
                          <span [innerHTML]="accItem.title"></span>
                          <i class="icon icon-arrow-right"></i>
                        </div>
                        <div class="accordion--content">
                          <ul>
                            <ng-container *ngFor="let contentItem of accItem.content">
                              <li>
                                <strong [innerHTML]="contentItem[0] + ': '"></strong>
                                <span [innerHTML]="contentItem[1]"></span>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>

              <!--
							Waves are removed for page content
							<div class="wave">
								<svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
									<path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"
												class="wave-path"></path>
								</svg>
							</div>
							-->

            </div>
          </ng-container>
        </div>
      </ng-container>

      <div (click)="onScrollTopClick()" class="scrollToTop" id="scrollToTop">
        <span>&#8250;</span>
      </div>

    </div>
  </div>

  <app-gallery-popup (closeGallery)="closeGalleryPopup()"
                     *ngIf="galleryPopupActive && slidesItems !== undefined && slidesItems !== null"
                     [slidesItems]="slidesItems"></app-gallery-popup>
</ng-container>
