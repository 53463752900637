import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http'

@Injectable()

export class SearchApiService {

  constructor(
    private http: HttpClient
  ) {
  }

  getResults(text: string) {
    return this.http.get("https://backend-muctrail.wuermentdecken.de/api/search?token=1qayxsw23edcvfr45tgb09ijnhz7&text=" + text);
  }
}
