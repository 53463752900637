<section class="section section--intro type-teaser" style="background-color: #223E6D !important;height:200px">
  <div class="intro__head" style="">
    <h1>Quellen & weiterführende Literatur</h1>
  </div>
  <div class="intro__content"></div>
  <div class="intro__footer"></div>
</section>
<section class="section section--blue" style="border-bottom: thin solid white">
  <div class="section__content">

    <div class="milestone">
      <span><b>Wissenschaftliche Methode (einzelne Aspekte auch an den anderen Stationen):</b></span><br/>
      <li><a href="https://www.science.lu/de/wie-forscher-arbeiten/was-ist-die-wissenschaftliche-methode" target="_blank">https://www.science.lu/de/wie-forscher-arbeiten/was-ist-die-wissenschaftliche-methode (Informationen wissenschaftliche Methode)</a></li>
      <li><a href="https://www.britannica.com/science/scientific-method" target="_blank">https://www.britannica.com/science/scientific-method (Informationen wissenschaftliche Methode)</a></li>
      <br/><br/>
    </div>

    <div class="milestone">
      <span><b>Hitzeinsel:</b></span><br/>
      <li><a href="https://www.zsk.tum.de/fileadmin/w00bqp/www/PDFs/Berichte/180207_Leitfaden_ONLINE.pdf" target="_blank">https://www.zsk.tum.de/fileadmin/w00bqp/www/PDFs/Berichte/180207_Leitfaden_ONLINE.pdf (PDF Leitfaden für klimaorientierte Kommunen in Bayern)</a></li>
     <br/><br/>
    </div>

    <div class="milestone">
      <span><b>Nachhaltiges Bauen:</b></span><br/>
      <li><a href="https://www.umweltbundesamt.de/themen/richtig-heizen" target="_blank">https://www.umweltbundesamt.de/themen/richtig-heizen (Tipps des Umweltbundesamts zum Heizen)</a></li>
      <li><a href="https://www.baunetzwissen.de/nachhaltig-bauen/fachwissen/baustoffe--teile/waermedaemmstoffe-682729" target="_blank">https://www.baunetzwissen.de/nachhaltig-bauen/fachwissen/baustoffe--teile/waermedaemmstoffe-682729 (Informationen zu Baustoffen)</a></li>
      <li><a href="https://passiv.de/de/02_informationen/01_wasistpassivhaus/01_wasistpassivhaus.htm" target="_blank">https://passiv.de/de/02_informationen/01_wasistpassivhaus/01_wasistpassivhaus.htm (Informationen zum Passivhaus)</a></li>
      <br/><br/>
    </div>

    <div class="milestone">
      <span><b>Mensch und Tier</b></span><br/>
      <li><a href="http://bln-berlin.de/wp-content/uploads/2016/04/Animal-Aided-Design-Broschuere.pdf" target="_blank">http://bln-berlin.de/wp-content/uploads/2016/04/Animal-Aided-Design-Broschuere.pdf (PDF Animal-Aided Design)</a></li>
      <li><a href="https://www.nabu.de/tiere-und-pflanzen/aktionen-und-projekte/stunde-der-wintervoegel/vogelportraets/13044.html" target="_blank">https://www.nabu.de/tiere-und-pflanzen/aktionen-und-projekte/stunde-der-wintervoegel/vogelportraets/13044.html (Tierporträt Haussperling)</a></li>
      <br/><br/>
    </div>

    <div class="milestone">
      <span><b>Bäume im Klimawandel:</b></span><br/>
      <li><a href="https://kinder.wdr.de/tv/die-sendung-mit-der-maus/av/video-internet-sachgeschichte-baumhoehe-messen-100.html" target="_blank">https://kinder.wdr.de/tv/die-sendung-mit-der-maus/av/video-internet-sachgeschichte-baumhoehe-messen-100.html (Video „Sendung mit der Maus“ über das Messen der Baumhöhe)</a></li>
      <li><a href="https://www.spektrum.de/lexikon/biologie/photosynthese/51369" target="_blank">https://www.spektrum.de/lexikon/biologie/photosynthese/51369 (Informationen Fotosynthese)</a></li>
      <li><a href="https://www.tum.de/nc/die-tum/aktuelles/pressemitteilungen/details/34305/" target="_blank">https://www.tum.de/nc/die-tum/aktuelles/pressemitteilungen/details/34305/  (Informationen Stadtbäume und Klimawandel)</a></li>
      <li><a href="https://www.tum.de/nc/die-tum/aktuelles/pressemitteilungen/details/34892/" target="_blank">https://www.tum.de/nc/die-tum/aktuelles/pressemitteilungen/details/34892/ (Informationen Baumwachstum im Klimawandel)</a></li>
      <li><a href="https://www.sueddeutsche.de/wissen/klimawandel-fragen-des-haushaltens-1.2474329" target="_blank">https://www.sueddeutsche.de/wissen/klimawandel-fragen-des-haushaltens-1.2474329 (Informationen Baumwachstum im Klimawandel)</a></li>
      <li><a href="https://de.wikipedia.org/wiki/Experiment" target="_blank">https://de.wikipedia.org/wiki/Experiment (Informationen zum Experiment)</a></li>
      <br/><br/>
    </div>

    <div class="milestone">
      <span><b>Verkehr in der Stadt:</b></span><br/>
      <li><a href="https://www.umweltbundesamt.de/themen/erklaerfilm-verkehr-in-der-stadt" target="_blank">https://www.umweltbundesamt.de/themen/erklaerfilm-verkehr-in-der-stadt (Erklärfilm des Umweltbundesamtes zum Verkehr)</a></li>
      <li><a href="https://www.bpb.de/politik/innenpolitik/stadt-und-gesellschaft/216883/stadt-im-klimawandel?p=all" target="_blank">https://www.bpb.de/politik/innenpolitik/stadt-und-gesellschaft/216883/stadt-im-klimawandel?p=all (Informationen Treibhausgase in der Stadt)</a></li>
      <li><a href="https://www.umweltbundesamt.de/service/uba-fragen/wie-funktioniert-der-treibhauseffekt" target="_blank">https://www.umweltbundesamt.de/service/uba-fragen/wie-funktioniert-der-treibhauseffekt (Informationen Treibhauseffekt)</a></li>
      <li><a href="https://www.verbraucherzentrale-bayern.de/sites/default/files/migration_files/media221043A.pdf" target="_blank">https://www.verbraucherzentrale-bayern.de/sites/default/files/migration_files/media221043A.pdf (PDF Klimabewusst unterwegs)</a></li>
      <li><a href="https://www.mvv-muenchen.de/fileadmin/Servicedownloads/mvv_lk_muenchen.pdf" target="_blank">https://www.mvv-muenchen.de/fileadmin/Servicedownloads/mvv_lk_muenchen.pdf (PDF Mobilität im Landkreis München)</a></li>
      <li><a href="https://www.umweltbundesamt.de/umwelttipps-fuer-den-alltag/mobilitaet/bus-bahn-fahren#textpart-2" target="_blank">https://www.umweltbundesamt.de/umwelttipps-fuer-den-alltag/mobilitaet/bus-bahn-fahren#textpart-2 (Informationen zur klimafreundlichen Fortbewegung)</a></li>
      <li><a href="https://de.wikipedia.org/wiki/Statistik" target="_blank">https://de.wikipedia.org/wiki/Statistik (Informationen zum Auswerten der Messwerte)</a></li>
      <br/><br/>
    </div>

  </div>
  <div class="wave">
    <svg preserveAspectRatio="none" style="height: 100%; width: 100%;" viewBox="0 0 500 150">
      <path class="wave-path" d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>
    </svg>
  </div>
</section>
