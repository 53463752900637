import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StartpageComponent} from "./startpage/startpage.component";
import {ArticleComponent} from "./content/article/article.component";
import {InfoComponent} from "./info/info.component";
import {FeedbackComponent} from "./feedback/feedback.component";
import {SearchComponent} from "./search/search.component";
import {MapBoxPageviewComponent} from "./content/map-box-pageview/map-box-pageview.component";
import {DateprotectionComponent} from "./dateprotection/dateprotection.component";
import {ImprintComponent} from "./imprint/imprint.component";
import {WuermOverviewComponent} from "./content/wuerm-overview/wuerm-overview.component";


const routes: Routes = [
  {
    path: '',
    component: StartpageComponent
  },
  {
    path: 'app-uebersicht',
    component: WuermOverviewComponent
  },
  {
    path: 'interaktive-map',
    component: MapBoxPageviewComponent
  },
  {
    path: 'article/:id',
    component: ArticleComponent
  },
  {
    path: 'suche',
    component: SearchComponent
  },
  {
    path: 'feedback',
    component: FeedbackComponent
  },
  {
    path: 'info',
    component: InfoComponent
  },
  {
    path: 'datenschutz',
    component: DateprotectionComponent
  },
  {
    path: 'impressum',
    component: ImprintComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
