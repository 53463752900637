///<reference path="../../../node_modules/@types/node/index.d.ts"/>
import {Component, HostListener, Inject, Input, OnInit, PLATFORM_ID, SimpleChanges} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

import {FeatureCollection} from './map-box';
import {environment} from "../../environments/environment";

import {SharingService} from "../services/system/sharing.service";
import {SystemService} from "../services/system/system.service";
import {GeolocationService} from "../services/geolocation/geolocation.service";
import {ContentService} from "../services/content/content.service";
import {EchtzeitproxyService} from "../services/echtzeitproxy/echtzeitproxy.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-mapbox',
  templateUrl: './map-box.component.html',
  styleUrls: ['./map-box.component.scss']
})
export class MapBoxComponent implements OnInit {

  @Input() mapboxOffsetToCenter = null;

  isMobileDevice = false;
  showNotification = true;

  /// default settings
  mapboxgl: any;
  MapboxLanguage: any;
  //MapboxDirections: any;
  map: any;
  //style = 'mapbox://styles/mapbox/outdoors-v10';
  style = 'https://cdn.muenchen-p.de/raw/upload/style-json/muenchenapp-swm-karten-komponente-style.json';

  lat = 48.173601;
  lng = 11.554312;

  bounds = [
    [10.592320, 47.422661], // Southwest coordinates
    [13.902400, 49.261500]  // Northeast coordinates
  ];
  mapboxControls;
  mapboxControlsOptions = {
    showCompass: true,
    showZoom: true
  };
  mapboxGeoControls;
  mapboxGeoControlsOptions = {
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: true
  };

  mapPointOffset = [-60, 90];

  // data
  source: any;
  markers: any;

  geoLocationAdded = false;

  mvStations = [];
  mvStation = null;

  mapFeatures: Array<any> = [];

  locatePopup: boolean = false;

  // directions api für routing https://github.com/mapbox/mapbox-gl-directions/blob/master/API.md
  // https://www.mapbox.com/help/how-directions-work/
  // https://www.mapbox.com/help/getting-started-directions-api/

  //https://github.com/mapbox/mapbox-gl-directions/blob/master/API.md#mapboxdirections

  // API DOC https://www.mapbox.com/api-documentation/?language=CLI#retrieve-directions

  // styling: https://github.com/mapbox/mapbox-gl-directions/blob/master/src/directions_style.js

  constructor(private sharingService: SharingService,
              private geolocationService: GeolocationService,
              private http: HttpClient,
              private contentService: ContentService,
              private echtzeitProxyServer: EchtzeitproxyService,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.mapboxgl = require('mapbox-gl');
      this.MapboxLanguage = require('@mapbox/mapbox-gl-language');

      //this.MapboxDirections = require('@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions');

      this.mapboxgl.accessToken = environment.mapbox.accessToken;
      this.mapboxControls = new this.mapboxgl.NavigationControl(this.mapboxControlsOptions);
      this.mapboxGeoControls = new this.mapboxgl.GeolocateControl(this.mapboxGeoControlsOptions);

      this.map = this.mapboxgl.Map;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }
    this.removeCurrentInfoIcons();
    this.initInfoIcons();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    if (isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }
    this.removeCurrentInfoIcons();
    this.initInfoIcons();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }

    if (isPlatformBrowser(this.platformId)) {
      this.initializeMap();
    }
  }

  initGeoLocationHandlers() {
    // https://github.com/mapbox/mapbox-gl-js/blob/d78b2d1cb162388fd16c4b3607cd2f27bd333a5f/src/ui/control/geolocate_control.js#L120
    //this.map.on('geolocate', this.geoLocationBtnActivated);
    this.mapboxGeoControls.on('trackuserlocationstart', (event: any) => {
    });

    this.mapboxGeoControls.on('trackuserlocationend', (event: any) => {
    });

    this.mapboxGeoControls.on('geolocate', (event: any) => {
      if (event !== undefined && event !== null && event.hasOwnProperty('coords')) {
        this.lat = event.coords.latitude;
        this.lng = event.coords.longitude;

        let userPosition = {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [this.lng, this.lat]
          }
        };

        this.checkIfStationNextToUser(userPosition);
      }
    });
  }

  getDistance(lat1, lon1, lat2, lon2) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    } else {
      let radlat1 = Math.PI * lat1 / 180,
        radlat2 = Math.PI * lat2 / 180,
        theta = lon1 - lon2,
        radtheta = Math.PI * theta / 180,
        dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;

      return dist;
    }
  }

  checkIfStationNextToUser(userPosition): void {
    let stationData = this.mapFeatures;
    for (let i = 0; i < stationData.length; i++) {
      let stationItem = stationData[i];

      let distance = this.getDistance(userPosition.geometry.coordinates[1],
        userPosition.geometry.coordinates[0],
        stationItem.geometry.coordinates[1],
        stationItem.geometry.coordinates[0]);

      if (distance <= 0.010) {
        if (!this.locatePopup) {
          setTimeout(() => {
            this.locatePopup = false;
          }, 10000);

          this.locatePopup = true;

          let infoPointElement = <HTMLElement>document.getElementsByClassName('icon--marker')[i];
          if (infoPointElement !== undefined && infoPointElement !== null) {

            if (stationItem.properties.subheadline === null) {
              stationItem.properties.subheadline = 'Hier tippen und mehr Infos erhalten'
            }

            let popup = new this.mapboxgl.Popup({
              closeButton: true,
              closeOnClick: true,
              anchor: 'bottom-left',
              offset: [-100, -40]
            });
            popup.setHTML(`
            <div class="station__popup" data-stationid="${stationItem.properties.stationId}">
              <div class="station__header">
                <p>${stationItem.properties.title}</p>
              </div>
              <div class="station__image"
                style="<background: url(https://cdn.muenchen-p.de/image/fetch/c_lfill,w_55,h_55/https://backend-muctrail.wuermentdecken.de/storage/${stationItem.properties.image}) no-repeat center; background-size: cover;"></div>
              <div class="station__content">
                <i class="icon icon--popup icon--small arrow-right"></i>
                <span>${stationItem.properties.subheadline}</span>
              </div>
            </div>
          `);

            popup.on('open', (e) => {
              let allOpenPopups = document.querySelectorAll('.station__popup'),
                elementForClick = <HTMLElement>allOpenPopups[allOpenPopups.length - 1];

              if (elementForClick === null && (allOpenPopups.length - 1) < 0) {
                elementForClick = <HTMLElement>allOpenPopups[0];
              }

              elementForClick.addEventListener('click', this.onOpenArticlePopupClicked.bind(this));
            });

            popup
              .setLngLat(stationItem.geometry.coordinates)
              .addTo(this.map);

            this.jumpToInfoPoint(i);

            break;
          }
        }
      }
    }
  }

  checkDeviceType() {
    this.isMobileDevice = SystemService.checkDeviceType(this.platformId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes !== undefined && changes !== null && changes.hasOwnProperty('mapboxOffsetToCenter')) {
      if (!this.isMobileDevice && changes.mapboxOffsetToCenter.currentValue !== null) {
        this.mapPointOffset = changes.mapboxOffsetToCenter.currentValue;
      }
    }
  }

  initializeMap() {
    if (!this.isMobileDevice) {
      this.mapPointOffset = [60, 90]
    }

    this.buildMap();
  }

  buildMap() {
    this.map = new this.mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 15,
      center: [this.lng, this.lat],
      attributionControl: false,
      maxBounds: this.bounds
    });

    this.map.on('style.load', () => {
      const waiting = () => {
        if (!this.map.isStyleLoaded()) {
          setTimeout(waiting, 200);
        } else {
          // Add map controls
          this.map.addControl(this.mapboxControls, 'top-right');

          // Add map geo controls
          this.map.addControl(this.mapboxGeoControls);

          let language = new this.MapboxLanguage();
          this.map.addControl(language);

          if (this.mapboxGeoControls !== undefined) {
            this.initGeoLocationHandlers();
          }
          this.initPathLines();
        }
      };
      waiting();
    });

    this.map.on('click', (event) => {
      this.mvStation = null;
    })

    this.map.on('zoomend', (event) => {
      let rZoomLevel = Math.round(this.map.getZoom() * 100) / 100;
      let elems = document.getElementsByClassName('icon--marker');

      if (rZoomLevel > 12.30) {
        for (let i = 0; i != elems.length; ++i) {
          let ele = <HTMLElement>elems[i];
          ele.style.visibility = "visible";
        }
      } else {
        for (let i = 0; i != elems.length; ++i) {
          let ele = <HTMLElement>elems[i];
          ele.style.visibility = "hidden";
        }
      }
    })

    this.map.on('moveend', (event) => {
      this.handleMvStations();
    });
  }

  handleMvDepartues(station, stationName) {
    this.echtzeitProxyServer.getMvDepartures(station).subscribe((result: any) => {
      let res = result.result;

      for (let i = 0; i < res.length; i++) {
        res[i].time = this.transform(new Date(res[i].departureTime));
      }

      this.mvStation = res;
      this.mvStation['name'] = stationName;
    });
  }

  transform(time: Date): string {
    let seconds = Math.round((time.getTime() - (new Date()).getTime()) / 1000);
    if (seconds < 60) {
      //return seconds + "s";
      return "-";
    } else if (seconds < 3600) {
      return Math.floor(seconds / 60).toString() + " Min.";
    } else {
      let h = Math.floor(seconds / 3600);
      return h.toString() + ":" + Math.floor((seconds - (h * 3600)) / 60) + " Std.";
    }
  }

  handleMvStations() {
    if (this.map.getZoom() > 13) {
      this.echtzeitProxyServer.getCategoryPlacesForBoundingBox('mv.public', this.map.getBounds(), this.map.getZoom()).subscribe((result: any) => {
        let res = result.results;

        if (this.mvStations.length > 0) {
          for (let i = 0; i < this.mvStations.length; i++) {
            this.mvStations[i].remove();
          }

          this.mvStations = [];
        }

        for (let i = 0; i < res.length; i++) {
          if (res[i].location.lng != null || res[i].location.lat != null) {
            let catCount = 0;

            for (let a = 0; a < res[i].categories.length; a++) {
              if (res[i].categories[a].id == 'mv.public.sbahn' || res[i].categories[a].id == 'mv.public.ubahn' || res[i].categories[a].id == 'mv.public.tram' || res[i].categories[a].id == 'mv.public.bus') {
                catCount = catCount + 1;
              }
            }

            let el = document.createElement('div');
            el.className = 'track-marker';
            el.style.color = '#F14D10';
            el.style.backgroundImage = "url('" + res[i].categoryIconStatic + "')";
            el.style.backgroundSize = "cover";
            el.style.fontSize = '13pt';
            el.style.display = 'block';
            el.style.position = 'absolute';
            el.className = 'marker-' + catCount;

            el.addEventListener('click', (event) => {
                this.handleMvDepartues(res[i].id, res[i].title);
                this.map.jumpTo({
                  center: [res[i].location.lng, res[i].location.lat],
                  zoom: 17
                });
              }
            );
            this.mvStations.push(new this.mapboxgl.Marker({
              element: el,
              offset: [0, 0]
            }).setLngLat([res[i].location.lng, res[i].location.lat])
              .addTo(this.map));

          }
        }
      });
    } else {
      this.mvStation = null;
      if (this.mvStations.length > 0) {
        for (let i = 0; i < this.mvStations.length; i++) {
          this.mvStations[i].remove();
        }

        this.mvStations = [];
      }
    }
  }

  initPathLines() {
    if (this.contentService.contentAll !== undefined &&
      this.contentService.contentAll.stations !== undefined &&
      this.contentService.contentAll.stations.length > 0) {
      this.initMapPoints(this.contentService.contentAll.stations);
      this.initMapTrackPoints();
    } else {
      this.contentService.getStationGeoAll().subscribe((result: any) => {
        this.initMapPoints(result);
      });
    }
  }

  initMapTrackPoints() {
    let trackPoints = this.contentService.contentAll.tracks;

    for (let i = 0; i < trackPoints.length; i++) {
      if (trackPoints[i].lng != null || trackPoints[i].lat != null) {
        let el = document.createElement('div');
        el.className = 'track-marker';
        el.style.color = '#F14D10';
        el.style.fontSize = '13pt';
        el.style.width = '120px';
        el.style.position = 'absolute';
        el.innerText = trackPoints[i].name;

        el.addEventListener('click', (event) => {
            this.map.jumpTo({
              center: [trackPoints[i].lng, trackPoints[i].lat],
              zoom: 14
            });
          }
        );
        new this.mapboxgl.Marker({
          element: el,
          offset: [35, 0]
        }).setLngLat([trackPoints[i].lng, trackPoints[i].lat])
          .addTo(this.map);

      }
    }
  }

  initMapPoints(data) {
    let stationsAll = data;

    this.mapFeatures = [];
    for (let i = 0; i < stationsAll.length; i++) {
      let stationItem = stationsAll[i];

      if (stationItem.hasOwnProperty('positions')) {
        for (let j = 0; j < stationItem.positions.length; j++) {
          if (stationItem.positions[j].lng != null) {
            let positionItem = stationItem.positions[j];
            let newFeature = {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [11.457066, 48.195707]
              },
              properties: {
                title: '',
                description: '',
                subheadline: '',
                image: '',
                stationId: 1
              }
            };
            newFeature.geometry.coordinates = [positionItem.lng, positionItem.lat];
            newFeature.properties.title = stationItem.name;
            newFeature.properties.subheadline = stationItem.subheadline;
            newFeature.properties.image = stationItem.medias[0].filename;
            newFeature.properties.stationId = stationItem.id;

            this.mapFeatures.push(newFeature);
          }
        }
      }
    }

    this.map.addLayer({
      "id": "path-middle",
      "type": "line",
      "source": {
        "type": "geojson",
        "data": {"type": "FeatureCollection",
          "features": [
            {
              "type": "Feature",
              "geometry": {
                "type": "LineString",
                "coordinates": [
                  [11.554642617702484, 48.16796379539441],
                  [11.554625183343887, 48.16798235519877],
                  [11.554595679044722, 48.167968044024896],
                  [11.554454863071442, 48.167916613210956],
                  [11.55439518392086, 48.16790677426672],
                  [11.554389148950575, 48.167904985367585],
                  [11.554357297718525, 48.16789134500951],
                  [11.554338186979294, 48.167882847735456],
                  [11.55432041734457, 48.16787569213516],
                  [11.554324440658092, 48.16789894783251],
                  [11.554327122867107, 48.167923097968576],
                  [11.554328463971615, 48.16793159523599],
                  [11.554289907217026, 48.168134634785616],
                  [11.55404046177864, 48.168332306878895],
                  [11.554025709629059, 48.16834125129948],
                  [11.553674340248108, 48.168438745382744],
                  [11.553434282541275, 48.16850582835994],
                  [11.553008817136288, 48.168604663786475],
                  [11.552926674485207, 48.16862299974964],
                  [11.552587375044823, 48.16861494981538],
                  [11.552429795265198, 48.16861137206644],
                  [11.552274897694588, 48.16860824153588],
                  [11.552338264882565, 48.168624565014454],
                  [11.552405655384064, 48.16864088848784],
                  [11.552561223506926, 48.168679125644886],
                  [11.552753001451492, 48.16874889161234],
                  [11.552832126617432, 48.16877728991164],
                  [11.55305776745081, 48.16891726870053],
                  [11.553141586482525, 48.16899709668862],
                  [11.553247533738613, 48.16909727281157],
                  [11.553269997239113, 48.16911873909817],
                  [11.55345942825079, 48.169161224430596],
                  [11.553635783493519, 48.16920013201994],
                  [11.553677693009377, 48.16921600809676],
                  [11.553732343018055, 48.16923702712048],
                  [11.553820855915546, 48.16927179793338],
                  [11.55389729887247, 48.16930120217154],
                  [11.55390702188015, 48.16930545069125],
                  [11.553916744887829, 48.169311711667],
                  [11.554076671600342, 48.1694181481382],
                  [11.554126292467117, 48.16945750274386],
                  [11.55416652560234, 48.16949596289751],
                  [11.554206758737564, 48.16953621186461],
                  [11.554257720708847, 48.16958540500374],
                  [11.554310023784637, 48.169634598095655],
                  [11.554269790649414, 48.16967037486015],
                  [11.554205417633055, 48.169706151599705],
                  [11.554143726825714, 48.16973477297336],
                  [11.554057896137238, 48.169759816662214],
                  [11.553947925567627, 48.16977591617],
                  [11.553655564785004, 48.16972582879582],
                  [11.55346244573593, 48.169677530210066],
                  [11.553398072719574, 48.169681107884635],
                  [11.553301513195036, 48.16970436276331],
                  [11.553186178207397, 48.169738350643954],
                  [11.553084254264832, 48.169784860338815],
                  [11.553049385547636, 48.169822425830816],
                  [11.553027927875519, 48.16985283597084],
                  [11.553003787994385, 48.16990650087985],
                  [11.55297964811325, 48.169970898696526],
                  [11.552974283695221, 48.16999951992243],
                  [11.552950143814087, 48.17040915946772],
                  [11.552955508232117, 48.17042168114898],
                  [11.553124487400055, 48.170639915674684],
                  [11.553210318088531, 48.17080806295444],
                  [11.553221046924591, 48.17094043382937],
                  [11.55284821987152, 48.170952955380926],
                  [11.552585363388062, 48.17091896830529],
                  [11.552429795265198, 48.170904657950906],
                  [11.552596092224121, 48.170917179511214],
                  [11.552833467721939, 48.17094937779508],
                  [11.55323177576065, 48.17094043382937],
                  [11.553377956151962, 48.17092433468714],
                  [11.553703844547272, 48.170928806671604],
                  [11.553843319416046, 48.17096368813697],
                  [11.554008275270462, 48.171060282840315],
                  [11.55412495136261, 48.171147933432174],
                  [11.554303318262098, 48.17127583147598],
                  [11.554409265518187, 48.17135543219537],
                  [11.554540693759918, 48.17142787768648],
                  [11.554677486419678, 48.17149942868837],
                  [11.554808914661407, 48.171571873976085],
                  [11.554945707321165, 48.171642530392646],
                  [11.554965823888777, 48.17165236862015],
                  [11.555136144161224, 48.171714975478174],
                  [11.555286347866058, 48.171761483380365],
                  [11.555404365062714, 48.1717999418061],
                  [11.555518358945847, 48.17181335752923],
                  [11.555707454681396, 48.171836611440966],
                  [11.555947512388228, 48.171836611440966],
                  [11.55613124370575, 48.171835717059956],
                  [11.55636191368103, 48.171834822678925],
                  [11.55658721923828, 48.171843766488635],
                  [11.556847393512726, 48.17183840020299],
                  [11.557040512561796, 48.17195824711527],
                  [11.557252407073975, 48.17205841745391],
                  [11.557458937168121, 48.17219615135004],
                  [11.557703018188477, 48.172339251110024],
                  [11.557831764221191, 48.17240543461394],
                  [11.558030247688293, 48.17258788599326],
                  [11.558129489421844, 48.172722041005066],
                  [11.558215320110321, 48.1728365196709],
                  [11.558365523815155, 48.172954575527406],
                  [11.558655202388763, 48.173038645441345],
                  [11.559041440486908, 48.173069053674126],
                  [11.559197008609772, 48.173145968535415],
                  [11.559336483478546, 48.173206784855665],
                  [11.559430360794067, 48.173269389815886],
                  [11.559524238109589, 48.1733892333837],
                  [11.55961275100708, 48.17351265407865],
                  [11.559677124023438, 48.173593145676186],
                  [11.559789776802063, 48.173704045003596],
                  [11.55987024307251, 48.17378274760658],
                  [11.559982895851135, 48.17389185783361],
                  [11.560066044330597, 48.17396161670955],
                  [11.560135781764984, 48.17402600943388],
                  [11.560200154781342, 48.174070726555954],
                  [11.560269892215729, 48.17409219076072],
                  [11.560200154781342, 48.174083247343155],
                  [11.560047268867493, 48.17411186627384],
                  [11.560012400150299, 48.17411544363905],
                  [11.559762954711914, 48.17411902100401],
                  [11.559505462646483, 48.17404747365734],
                  [11.5591299533844, 48.173932997695],
                  [11.558853685855865, 48.1738095780118],
                  [11.558679342269897, 48.17372372066567],
                  [11.558542549610138, 48.17364501797211],
                  [11.558448672294617, 48.17367184844939],
                  [11.558277010917664, 48.17390258997454],
                  [11.558108031749724, 48.17417268144848],
                  [11.557920277118683, 48.17421739844265],
                  [11.557684242725372, 48.17428357952248],
                  [11.55748039484024, 48.17439984337478],
                  [11.557314097881317, 48.17443740548609],
                  [11.557236313819885, 48.17446244687833],
                  [11.556925177574158, 48.174371224604805],
                  [11.556726694107056, 48.17428715687571],
                  [11.556463837623594, 48.17423528522943],
                  [11.556190252304077, 48.17422097580054],
                  [11.555938124656677, 48.17411723232156],
                  [11.555699408054352, 48.17401527731875],
                  [11.555476784706114, 48.17393657507268],
                  [11.555329263210297, 48.17389543521416],
                  [11.555184423923492, 48.173861450088744],
                  [11.555337309837341, 48.173992024395],
                  [11.555425822734833, 48.17416194936409],
                  [11.555476784706114, 48.174373013278405],
                  [11.555460691452026, 48.17452683897368],
                  [11.555449962615967, 48.17466635490289],
                  [11.555447280406952, 48.174787983864775],
                  [11.555479466915129, 48.17495611754288],
                  [11.55550092458725, 48.17514929172641],
                  [11.555522382259369, 48.17527807411116],
                  [11.555567979812622, 48.17548019025719],
                  [11.555589437484741, 48.17557319918947],
                  [11.555576026439667, 48.17572165540485],
                  [11.555573344230652, 48.17584864772991],
                  [11.555538475513458, 48.17595954217904],
                  [11.555503606796265, 48.176116940340165],
                  [11.555455327033997, 48.176236777252484],
                  [11.55538558959961, 48.176388808753124],
                  [11.555289030075073, 48.17653726260695],
                  [11.555219292640686, 48.176651732754934],
                  [11.555039584636688, 48.17673221942472],
                  [11.554929614067078, 48.17677514559694],
                  [11.554825007915497, 48.176803763025106],
                  [11.55469626188278, 48.17681807173321],
                  [11.554599702358246, 48.176803763025106],
                  [11.554438769817352, 48.17676977982735],
                  [11.554336845874786, 48.17674295097114],
                  [11.554173231124878, 48.1765980749049],
                  [11.554006934165955, 48.17645140982825],
                  [11.55387818813324, 48.176326207601576],
                  [11.553770899772644, 48.176218891163956],
                  [11.55363142490387, 48.176072225002635],
                  [11.55349999666214, 48.175920192563254],
                  [11.553365886211395, 48.17575027342106],
                  [11.553272008895874, 48.175637589889874],
                  [11.553167402744293, 48.175492710700205],
                  [11.552926003932951, 48.17531742421975],
                  [11.552856266498566, 48.17526376497327],
                  [11.5526282787323, 48.17514750307991],
                  [11.55247539281845, 48.17507059122097],
                  [11.552217900753021, 48.17497042676661],
                  [11.551957726478577, 48.17487205077273],
                  [11.551807522773743, 48.17479871581815],
                  [11.551600992679596, 48.174677086881715],
                  [11.551351547241211, 48.17452505030547],
                  [11.551287174224852, 48.174410575409375],
                  [11.551088690757751, 48.1742603267204],
                  [11.551002860069275, 48.17418162485045],
                  [11.5508633852005, 48.17408861339388],
                  [11.550675630569458, 48.17405641708111],
                  [11.55038595199585, 48.17400991126037],
                  [11.550133824348448, 48.173945518515836],
                  [11.549940705299377, 48.17392405424968],
                  [11.549833416938782, 48.17382388755552],
                  [11.549798548221588, 48.17373981892902],
                  [11.549788489937782, 48.1735819662952],
                  [11.549760997295378, 48.17362847250412],
                  [11.549740210175514, 48.17364322927312],
                  [11.549708023667336, 48.17365396146608],
                  [11.54967449605465, 48.17365888038712],
                  [11.549592018127441, 48.17365038406868],
                  [11.549441814422607, 48.17360208917924],
                  [11.54926747083664, 48.173480457404274],
                  [11.549154818058014, 48.17336955759329],
                  [11.54910922050476, 48.17328369951037],
                  [11.549087762832642, 48.17317279927383],
                  [11.549074351787567, 48.1730118146327],
                  [11.549028754234314, 48.17273277339085],
                  [11.548953652381897, 48.172577153577144],
                  [11.548830270767212, 48.17242868825721],
                  [11.548497676849365, 48.172194362600514],
                  [11.548299193382263, 48.17206736122461],
                  [11.548030972480774, 48.171949303325505],
                  [11.547915637493132, 48.17185360467752],
                  [11.547655463218689, 48.17160675500401],
                  [11.54749184846878, 48.171506583783085],
                  [11.547399312257765, 48.171470808299595],
                  [11.547278612852095, 48.171444871058476],
                  [11.547190770506859, 48.17144129350696],
                  [11.546575538814068, 48.17147192628383],
                  [11.546547375619411, 48.171463206006145],
                  [11.546403206884861, 48.1714200517896],
                  [11.546263732016085, 48.17137756832851],
                  [11.545990146696568, 48.17143749235817]
                ]
              },
              "properties": []
            }
          ]}
      },
      "layout": {
        "line-join": "round",
        "line-cap": "round"
      },
      "paint": {
        "line-color": "#F14D10",
        "line-width": 4
      }
    });


    this.initInfoIcons();
  }

  initInfoIcons() {
    let geojson = {
      type: 'FeatureCollection',
      features: this.mapFeatures
    };

    // add markers to map
    geojson.features.forEach((marker, index) => {

      // create a HTML element for each feature
      let el = document.createElement('div');
      el.className = 'icon icon--marker icon--small info';
      el.style.visibility = 'visible';
      el.dataset.markerindex = index.toString();

      if (marker.properties.subheadline === null) {
        marker.properties.subheadline = 'Hier tippen und mehr Infos erhalten'
      }

      let popup = new this.mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
        anchor: 'bottom-left',
        offset: [-100, -40]
      });
      popup.setHTML(`
				<div class="station__popup" data-stationid="${marker.properties.stationId}">
					<div class="station__header">
						<p>${marker.properties.title}</p>
					</div>
					<div class="station__image"
						style="background: url(https://backend-muctrail.wuermentdecken.de/storage/${marker.properties.image}) no-repeat center; background-size: cover;"></div>
					<div class="station__content">
						<i class="icon icon--popup icon--small arrow-right"></i>
						<span>${marker.properties.subheadline}</span>
					</div>
				</div>
				`);

      el.addEventListener('click', (event) => {
          let clickedMarker = <HTMLElement>event.target,
            markerIndex = clickedMarker.dataset.markerindex;
          this.jumpToInfoPoint(markerIndex);
        }
      );

      popup.on('open', (e) => {
        let allOpenPopups = document.querySelectorAll('.station__popup'),
          elementForClick = <HTMLElement>allOpenPopups[allOpenPopups.length - 1];

        elementForClick.addEventListener('click', this.onOpenArticlePopupClicked.bind(this));
      });

      // make a marker for each feature and add to the map
      new this.mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .setPopup(popup) // add popups
        .addTo(this.map);

    });
  }

  onOpenArticlePopupClicked(event) {
    let clickedElement = event.target,
      articleId = parseInt(clickedElement.dataset.stationid, 10);

    if (!clickedElement.classList.contains('station__popup')) {
      clickedElement = clickedElement.closest('.station__popup');
      articleId = parseInt(clickedElement.dataset.stationid, 10);
    }

    let sharingData = {
      articlePopupOpened: true,
      articleId: articleId
    };
    this.sharingService.emitChange(sharingData);
  }

  removeCurrentInfoIcons() {
    let currentMarkers = document.getElementsByClassName('icon--marker');

    if (currentMarkers !== undefined && currentMarkers !== null) {
      while (currentMarkers[0]) {
        currentMarkers[0].parentNode.removeChild(currentMarkers[0]);
      }
    }
  }

  jumpToInfoPoint(index = null) {
    let clickedPlaceCoordinates = [this.mapFeatures[index].geometry.coordinates[0], this.mapFeatures[index].geometry.coordinates[1]];

    this.map.easeTo({
      center: clickedPlaceCoordinates,
      offset: this.mapPointOffset
    });
  }

  closeNotification() {
    this.showNotification = false;
  }

  onMvgEntryClose() {
    this.mvStation = null;
  }
}
