<header>
  <div id="logo">
    <a (click)="onLogoClick()">
      <img alt="" src="../../assets/images/signet_umweltwanderweg_rgb.jpg">
    </a>
  </div>

  <ng-container *ngIf="viewmode === 1 || viewmode === 2">
    <div (click)="onMainMenuOpened()" *ngIf="!mainMenuState" id="menu-trigger">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div (click)="onMainMenuOpened()" *ngIf="mainMenuState" id="menu-trigger-close">
      <i class="icon icon--small close"></i>
    </div>
  </ng-container>
</header>
